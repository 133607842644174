import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Row,
  Button,
  Modal,
  Input,
  Checkbox,
  notification,
  Popconfirm,
  Skeleton,
  Tag,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import { SERVER_URL } from "../../config";
import { AuthContext } from "../../context/AuthContext";

const columns = [
  {
    title: "#",
    dataIndex: "key",
    align: "center",
  },
  {
    title: "Naziv",
    dataIndex: "title",
    key: "title",
    align: "center",
  },
  {
    title: "Cena",
    dataIndex: "price",
    key: "price",
    align: "center",
  },
  {
    title: "Dostupno",
    dataIndex: "available",
    key: "available",
    align: "center",
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    align: "center",
  },
];

const SideDishes = () => {
  const [products, setProducts] = useState([]);
  const [refetcher, setRefetcher] = useState();
  const [loading, setLoading] = useState(true);
  const [createNew, setCreateNew] = useState(false);
  const [imageView, setImageView] = useState(false);
  const [newProductData, setNewProductData] = useState({});
  const { user } = useContext(AuthContext);
  const [api, contextHolder] = notification.useNotification();
  const [currentProduct, setCurrentProduct] = useState();

  const openNotification = (title, message, placement = "bottomRight") => {
    api.info({
      message: title || "",
      description: message || "",
      placement,
    });
  };

  const getProducts = async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/side-dishes`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });
      const prodData = data.map((prod, i) => {
        return {
          key: i + 1,
          title: prod.title,
          price: parseFloat(prod.price).toFixed(2),
          available: prod.available ? (
            <Tag color="green">
              <CheckOutlined />
            </Tag>
          ) : (
            <Tag color="red">
              <CloseOutlined />
            </Tag>
          ),
          actions: (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Popconfirm
                title="Delete a side dish"
                description="Are you sure to delete this side dish?"
                onConfirm={() => handleDelete(prod._id)}
                okText="Yes"
                cancelText="No"
                placement="left"
              >
                <DeleteOutlined style={{ cursor: "pointer" }} />
              </Popconfirm>
            </div>
          ),
        };
      });
      setProducts(prodData);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getProducts();
  }, [refetcher]);

  const onProductCreate = async () => {
    try {
      const response = await Axios.post(
        `${SERVER_URL}/create-side-dish`,
        newProductData,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      setCreateNew(false);
      setNewProductData();
      setRefetcher((r) => !r);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDelete = async (id) => {
    console.log(id);
    try {
      const response = await Axios.delete(
        `${SERVER_URL}/delete-side-dish/${id}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      openNotification("Remove info", "Side dish removed successfully!");
      setRefetcher((r) => !r);
    } catch (error) {
      console.log(error.message);
      openNotification(
        `Remove error (Status: ${error.response.status})`,
        error.response?.data?.message
      );
    }
  };

  return (
    <>
      {contextHolder}
      <h1>Dodaci</h1>
      <Row justify={"end"} style={{ paddingBottom: "10px" }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size={"large"}
          onClick={() => setCreateNew(true)}
        >
          Kreiraj novi
        </Button>
      </Row>
      {loading ? (
        <Skeleton active />
      ) : (
        <Table dataSource={products} columns={columns} size={"small"} />
      )}
      <Modal
        title="Create a new product"
        centered
        open={createNew}
        onOk={onProductCreate}
        onCancel={() => setCreateNew(false)}
      >
        <Row>
          <div style={{ marginTop: "10px" }}>Title:</div>
          <Input
            name={"title"}
            placeholder="Title"
            value={newProductData?.title}
            onChange={(e) =>
              setNewProductData((d) => {
                return { ...d, title: e.target.value };
              })
            }
          />

          <div style={{ marginTop: "10px" }}>Price:</div>
          <Input
            type={"number"}
            name={"price"}
            placeholder=""
            value={newProductData?.price}
            onChange={(e) =>
              setNewProductData((d) => {
                return { ...d, price: e.target.value };
              })
            }
          />

          <Checkbox
            style={{ marginTop: "10px" }}
            defaultChecked={false}
            onChange={(e) =>
              setNewProductData((d) => {
                return { ...d, available: e.target.checked };
              })
            }
          >
            Available
          </Checkbox>
        </Row>
      </Modal>
    </>
  );
};

export default SideDishes;
