import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Row,
  Button,
  Modal,
  Input,
  Checkbox,
  notification,
  Popconfirm,
  Skeleton,
  Tabs,
  Tag,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import {
  PlusOutlined,
  DeleteOutlined,
  CloseOutlined,
  CheckOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import { SERVER_URL } from "../../config";
import { AuthContext } from "../../context/AuthContext";
import TextArea from "antd/es/input/TextArea";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { CSS } from "@dnd-kit/utilities";

import "../../styles/Antd.css";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [refetcher, setRefetcher] = useState();
  const [loading, setLoading] = useState(true);
  const [categoryModal, setCategoryModal] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [imageView, setImageView] = useState(false);
  const [newCategoryData, setNewCategoryData] = useState({});
  const { user } = useContext(AuthContext);
  const [api, contextHolder] = notification.useNotification();
  const [currentCategory, setCurrentCategory] = useState();
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("sr");
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [movedTableElement, setMovedTableElement] = useState([]);

  const columns = [
    {
      title: "#",
      dataIndex: "key",
    },
    // {
    //   title: activeTab === "sr" ? "Fotografija" : "Image",
    //   dataIndex: "img",
    // },
    {
      title: activeTab === "sr" ? "Kategorija" : "Category", // Change dynamically based on the active tab
      dataIndex: "category",
      key: "category",
    },
    {
      title: activeTab === "sr" ? "Opis" : "Description", // Change dynamically based on the active tab
      dataIndex: "description",
      key: "description",
    },
    {
      title: activeTab === "sr" ? "Dostupno" : "Available", // Change dynamically based on the active tab
      dataIndex: "available",
      key: "available",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const RowCategory = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        }
      ),
      transition,
      cursor: "move",
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
          }
        : {}),
    };
    return (
      <tr
        {...props}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      />
    );
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    })
  );
  const onDragEnd = useCallback(
    ({ active, over }) => {
      setIsOrderChanged(true);
      if (active.id !== over?.id) {
        setMovedTableElement((prevState) => [...prevState, active.id]);

        setCategories((prev) => {
          const activeIndex = prev.findIndex((i) => i.key === active.id);
          const overIndex = prev.findIndex((i) => i.key === over?.id);
          return arrayMove(prev, activeIndex, overIndex);
        });
      }
    },
    [setIsOrderChanged, setMovedTableElement, setCategories]
  );

  const openNotification = (title, message, placement = "bottomRight") => {
    api.info({
      message: title || "",
      description: message || "",
      placement,
    });
  };

  const onImageView = (item) => {
    setCurrentCategory(item);
    setImageView(true);
  };

  const onEditCategory = (item) => {
    setCurrentCategory(item);
    setModalMode("edit"); // Set modal mode to 'edit'
    setNewCategoryData({
      title: item.title[activeTab],
      description: item.description[activeTab],
      available: item.available,
      language: activeTab,
      seoTitle:
        item && item.seoTitle && item.seoTitle[activeTab]
          ? item.seoTitle[activeTab]
          : "",
      seoDescription:
        item && item.seoDescription && item.seoDescription[activeTab]
          ? item.seoDescription[activeTab]
          : "",
    });
    setCategoryModal(true); // Open the modal
  };

  const getCategories = async () => {
    try {
      const { data } = await Axios.get(
        `${SERVER_URL}/categories-all-with-languages`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      const catData = data.categories.map((cat, i) => {
        return {
          id: cat._id,
          key: i + 1,
          img: (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <img
                src={cat.img}
                style={{
                  maxWidth: "50px",
                  maxHeight: "50px",
                  cursor: "pointer",
                }}
                alt=""
              />
            </div>
          ),
          // thumb: <img src={cat.img} style={{ width: '60px', cursor: 'pointer' }} onClick={() => onImageView(cat)} />,
          category: (
            <Link to={`/categories/details/${cat._id}`}>
              {cat.title[activeTab] || "No title for selected language"}
            </Link>
          ),
          description:
            cat.description[activeTab] || "No title for selected language",
          available: cat.available ? (
            <Tag color="green">
              <CheckOutlined />
            </Tag>
          ) : (
            <Tag color="red">
              <CloseOutlined />
            </Tag>
          ),
          actions: (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <EditOutlined
                style={{ cursor: "pointer", paddingRight: "8px" }}
                onClick={() => onEditCategory(cat)}
              />
              <Popconfirm
                title="Delete a category"
                description="Are you sure to delete this category?"
                onConfirm={() => handleDelete(cat._id)}
                okText="Yes"
                cancelText="No"
                placement="left"
              >
                <DeleteOutlined style={{ cursor: "pointer" }} />
              </Popconfirm>
            </div>
          ),
        };
      });
      setCategories(catData);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCategories();
  }, [refetcher, activeTab]);

  const onUpdateCategory = async () => {
    try {
      const response = await Axios.put(
        `${SERVER_URL}/update-category/${currentCategory._id}`, // Use the correct API endpoint
        newCategoryData,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      openNotification("Update Successful", "Category updated successfully!");
      setCategoryModal(false); // Close the modal
      setRefetcher((r) => !r); // Refetch categories to update the table
    } catch (error) {
      console.log(error.message);
      openNotification(
        `Update Error (Status: ${error.response.status})`,
        error.response?.data?.message
      );
    }
  };

  const onChangeOrderCategory = async () => {
    let idList = [];
    idList = categories.map((cat, idx) => {
      return { id: cat.id, key: idx };
    });

    try {
      const response = await Axios.put(
        `${SERVER_URL}/update-categories-order`,
        { categories: idList },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      openNotification(
        "Update Successful",
        "Category order change successfully!"
      );
      setRefetcher((r) => !r);
    } catch (error) {
      console.log(error.message);
      openNotification(
        `Update Error (Status: ${error.response.status})`,
        error.response?.data?.message
      );
    }
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    setLoading(true);
    // You may want to add some logic here to fetch data based on the selected tab
    setTimeout(() => {
      setLoading(false);
    }, 400); // Simulating a delay, replace with your actual data fetching logic
  };

  const onCategoryCreate = async () => {
    setModalMode("create");
    try {
      const response = await Axios.post(
        `${SERVER_URL}/categories`,
        newCategoryData,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      setCategoryModal(false);
      setNewCategoryData();
      setRefetcher((r) => !r);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await Axios.delete(`${SERVER_URL}/category/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });
      openNotification("Remove info", "Category removed successfully!");
      setRefetcher((r) => !r);
    } catch (error) {
      console.log(error.message);
      openNotification(
        `Remove error (Status: ${error.response.status})`,
        error.response?.data?.message
      );
    }
  };

  const catTableMemo = useMemo(
    () => (
      <Table
        pagination={false}
        dataSource={categories}
        columns={columns}
        size={"small"}
        components={{
          body: {
            row: RowCategory,
          },
        }}
        rowClassName={(record, index) => {
          if (movedTableElement.includes(record.key)) {
            return "moved-table-row";
          } else {
            return "";
          }
        }}
      />
    ),
    [columns, RowCategory]
  );

  return (
    <>
      {contextHolder}
      <h1>{activeTab === "sr" ? "Kategorije" : "Categories"}</h1>
      <Row
        justify={"end"}
        style={{ paddingBottom: "10px", display: "flex", gap: "20px" }}
      >
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size={"large"}
          onClick={() => {
            setModalMode("create");
            setCategoryModal(true);
          }}
        >
          Kreiraj novu
        </Button>
        <Button
          disabled={!isOrderChanged ? true : false}
          type="primary"
          icon={<UnorderedListOutlined />}
          size={"large"}
          onClick={onChangeOrderCategory}
        >
          Promeni redosled
        </Button>
      </Row>
      {loading ? (
        <Skeleton active />
      ) : (
        <Tabs
          activeKey={activeTab}
          defaultActiveKey="sr"
          tabBarStyle={{ marginBottom: "10px" }}
          onChange={handleTabChange}
        >
          <TabPane tab="SR" key="sr">
            {/* <Table dataSource={categories} columns={columns} size={"small"} /> */}
            <DndContext
              sensors={sensors}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEnd}
            >
              <SortableContext
                // rowKey array
                items={categories.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
              >
                {catTableMemo}
              </SortableContext>
            </DndContext>
          </TabPane>
          <TabPane tab="EN" key="en">
            {/* <Table dataSource={categories} columns={columns} size={"small"} /> */}
            <DndContext
              sensors={sensors}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEnd}
            >
              <SortableContext
                // rowKey array
                items={categories.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
              >
                {catTableMemo}
              </SortableContext>
            </DndContext>
          </TabPane>
          <TabPane tab="DE" key="de">
            {/* <Table dataSource={categories} columns={columns} size={"small"} /> */}
            <DndContext
              sensors={sensors}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEnd}
            >
              <SortableContext
                // rowKey array
                items={categories.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
              >
                {catTableMemo}
              </SortableContext>
            </DndContext>
          </TabPane>
          <TabPane tab="HU" key="hu">
            {/* <Table dataSource={categories} columns={columns} size={"small"} /> */}
            <DndContext
              sensors={sensors}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEnd}
            >
              <SortableContext
                // rowKey array
                items={categories.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
              >
                {catTableMemo}
              </SortableContext>
            </DndContext>
          </TabPane>
        </Tabs>
      )}
      <Modal
        title={
          modalMode === "create"
            ? "Kreiraj novu kategoriju"
            : "Izmeni kategoriju"
        }
        centered
        open={categoryModal}
        onOk={modalMode === "create" ? onCategoryCreate : onUpdateCategory}
        onCancel={() => setCategoryModal(false)}
      >
        <Row>
          <div style={{ marginTop: "10px" }}>Naziv:</div>
          <Input
            name={"title"}
            placeholder="Naziv"
            value={newCategoryData?.title}
            onChange={(e) =>
              setNewCategoryData((d) => {
                return { ...d, title: e.target.value };
              })
            }
          />

          <div style={{ marginTop: "10px" }}>Opis:</div>
          <TextArea
            style={{ height: "150px" }}
            name={"description"}
            placeholder="Opis"
            value={newCategoryData?.description}
            onChange={(e) =>
              setNewCategoryData((d) => {
                return { ...d, description: e.target.value };
              })
            }
          />

          <div style={{ marginTop: "10px" }}>Seo naziv:</div>
          <Input
            name={"title"}
            placeholder="Seo naziv"
            value={newCategoryData?.seoTitle}
            onChange={(e) =>
              setNewCategoryData((d) => {
                return { ...d, seoTitle: e.target.value };
              })
            }
          />

          <div style={{ marginTop: "10px" }}>Seo opis:</div>
          <Input
            name={"title"}
            placeholder="Seo opis"
            value={newCategoryData?.seoDescription}
            onChange={(e) =>
              setNewCategoryData((d) => {
                return { ...d, seoDescription: e.target.value };
              })
            }
          />

          <Checkbox
            style={{ marginTop: "10px" }}
            checked={newCategoryData?.available}
            onChange={(e) =>
              setNewCategoryData((d) => {
                return { ...d, available: e.target.checked };
              })
            }
          >
            Dostupno
          </Checkbox>
        </Row>
      </Modal>
      <Modal
        title={currentCategory?.title.sr}
        centered
        open={imageView}
        onCancel={() => setImageView(false)}
        footer={null}
        closeIcon={false}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={currentCategory?.img} style={{ width: "400px" }} />
        </div>
      </Modal>
    </>
  );
};

export default Categories;
