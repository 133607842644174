import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  Row,
  Button,
  Modal,
  Input,
  notification,
  Popconfirm,
  Skeleton,
  Select,
  Tabs,
  Pagination,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import { SERVER_URL } from "../../config";
import { AuthContext } from "../../context/AuthContext";
import TextArea from "antd/es/input/TextArea";
import { useSocket } from "../../socketContext";
import "../../styles/orders.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSound from "use-sound";
import soundNotification from "../../notification/notification-sound.mp3";
import moment from "moment";
import { inactivityReload } from "../../utils/inactivityReload";

const mongoose = require("mongoose");

const columns = [
  {
    title: "Šifra",
    dataIndex: "orderNumber",
    align: "center",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Search Order Number"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={confirm}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={confirm}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Pretraži
        </Button>
        <Button
          onClick={() => {
            clearFilters();
            confirm();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Poništi
        </Button>
      </div>
    ),
    onFilter: (value, record) =>
      record.orderNumber.toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Kupac",
    dataIndex: "customerEmail",
    key: "customer",
    align: "center",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Search Customer"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={confirm}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={confirm}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Pretraži
        </Button>
        <Button
          onClick={() => {
            clearFilters();
            confirm();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Poništi
        </Button>
      </div>
    ),
    onFilter: (value, record) =>
      record.customer.toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Ukupna cena",
    dataIndex: "totalPrice",
    key: "price",
    align: "center",
  },
  {
    title: "Poruka",
    dataIndex: "message",
    key: "message",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    align: "center",

    onFilter: (value, record) => record.status === value,
  },
  // {
  //   title: "Garson",
  //   dataIndex: "garsonSuccess",
  //   key: "garsonSuccess",
  //   align: "center",
  // },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    align: "center",
  },
];

const Orders = () => {
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [orders, setOrders] = useState([]);
  const [ordersByStatus, setOrdersByStatus] = useState([]);
  const [ordersCount, setOrdersCount] = useState(1);
  const [customersList, setCustomersList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const { user } = useContext(AuthContext);
  const [orderModal, setOrderModal] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const socket = useSocket();
  const [newOrderData, setNewOrderData] = useState({
    customerId: undefined,
    totalPrice: undefined,
    message: "",
    items: [],
    customerEmail: undefined,
    paymentMethod: undefined,
    deliveryFee: 0,
    status: "Pending",
    contact: undefined,
    discountPrice: undefined,
  });
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(true);
  const [loadingOrdersByStatus, setLoadingOrdersByStatus] = useState(false);
  const [refetcher, setRefetcher] = useState();
  const [currentOrder, setCurrentOrder] = useState();
  const [calculatedTotalPrice, setCalculatedTotalPrice] = useState(0);
  const [newOrderInfoOpen, setNewOrderInfoOpen] = useState(false);
  const [play] = useSound(soundNotification);
  const [activeTab, setActiveTab] = useState("activeOrders");
  const [currentPage, setCurrentPage] = useState(1);

  const { TabPane } = Tabs;

  const handleTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
  };
  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  const openNotification = (title, message, placement = "bottomRight") => {
    api.info({
      message: title || "",
      description: message || "",
      placement,
    });
  };

  const calculateOrderPrice = (newOrderData) => {
    let totalPrice = 0;
    // Iterate through items in newOrderData
    for (const item of newOrderData.items) {
      // Calculate the item's price based on its quantity
      let itemPrice = (item.id?.price || 0) * item.quantity;
      // Iterate through side dishes for the item
      for (const sideDish of item.sideDishes) {
        // Calculate the side dish price based on its quantity
        const sideDishPrice =
          (sideDish.sideDish?.price || 0) * sideDish.quantity;
        // Add the side dish price to the item's price
        itemPrice += sideDishPrice;
      }
      // Add the item's total price to the order's total price
      totalPrice += itemPrice;
    }
    return totalPrice.toFixed(2); // Round to 2 decimal places
  };

  // Function to fetch all customers
  const fetchAllCustomers = async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/get-all-customers`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setCustomersList(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  // Function to fetch all products
  const fetchAllProducts = async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/products-all`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setProductsList(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  // Function to fetch all products
  const fetchProductByTitle = async (prodTitle) => {
    try {
      const requestBody = {
        title: prodTitle, // You can add other properties to the request body as needed
      };
      const { data } = await Axios.post(`${SERVER_URL}/get-product-by-title`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
        title: prodTitle,
      });
      return data;
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (socket) {
      console.log("SOCKEEEET");

      const handleAllOrders = async (data) => {
        setNewOrderInfoOpen(true);
        play();

        toast.success(`Nova poružbina sa brojem ${data[0].orderNumber} `, {
          position: "bottom-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        const processedOrders = data.map((item) => ({
          orderNumber: item.orderNumber,
          customerEmail: item.customerEmail,
          totalPrice: parseFloat(item.totalPrice + item.deliveryFee).toFixed(2),
          message: item.message,
          status: item.status,
          // garsonSuccess: item.garsonSuccess ? "DA" : "NE",
          actions: (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <EyeOutlined
                style={{ cursor: "pointer", paddingRight: "8px" }}
                onClick={() => onViewOrder(item)}
              />
              <EditOutlined
                style={{ cursor: "pointer", paddingRight: "8px" }}
                onClick={() => onEditOrder(item)}
              />
              {user?.role !== "dispatcher" && (
                <Popconfirm
                  title="Delete order"
                  description="Are you sure to delete this order?"
                  onConfirm={() => handleDelete(item._id)}
                  okText="Yes"
                  cancelText="No"
                  placement="left"
                >
                  <DeleteOutlined style={{ cursor: "pointer" }} />
                </Popconfirm>
              )}
            </div>
          ),
        }));
        setActiveTab("activeOrders");

        // setRefetcher((r) => !r);

        // setOrders(processedOrders);
        setOrdersByStatus(processedOrders);
      };

      socket.on("allOrders", handleAllOrders);

      return () => {
        socket.off("allOrders", handleAllOrders);
      };
    }
  }, [socket, play]);

  useEffect(() => {
    fetchAllCustomers();
    fetchAllProducts();
    getOrders();
    // setOrdersByStatus();
  }, [refetcher]);

  useEffect(() => {
    getOrdersByStatus();
  }, [activeTab, currentPage, refetcher]);

  // function for page reloading on inactivity
  useEffect(() => {
    const cleanup = inactivityReload(120000); // 2 minutes

    console.log("REFRESH");

    return () => {
      cleanup();
    };
  }, []);

  const onOrderCreate = async () => {
    try {
      const response = await Axios.post(
        `${SERVER_URL}/create-order`,
        {
          customerId: newOrderData.customerId,
          totalPrice: newOrderData.totalPrice,
          items: newOrderData.items.map((item) => ({
            _id: item?._id,
            quantity: item.quantity,
            sideDishes: item.sideDishes.map((sideDish) => ({
              _id: sideDish?._id,
              quantity: sideDish.quantity,
            })),
          })),
          message: newOrderData.message,
          paymentMethod: newOrderData.paymentMethod,
          status: newOrderData.status,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      setOrderModal(false);
      setRefetcher((r) => !r);
    } catch (error) {
      console.error("Create Order Error:", error.message);
    }
  };

  const onOrderUpdate = async () => {
    try {
      // Remove side dishes with a quantity of 0 before updating the order
      const updatedItems = newOrderData.items.map((item) => ({
        ...item,
        sideDishes: item.sideDishes.filter((sideDish) => sideDish.quantity > 0),
      }));

      // Create a copy of the new order data with updated items
      const updatedOrderData = {
        ...newOrderData,
        items: updatedItems,
        phoneNumber: newOrderData.contact,
      };
      const response = await Axios.put(
        `${SERVER_URL}/update-order/${currentOrder._id}`,
        updatedOrderData,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      openNotification("Update Successful", "Order updated successfully!");
      setOrderModal(false);
      setRefetcher((r) => !r);
      window.location.reload();
    } catch (error) {
      console.error(error.message);
      openNotification(
        `Update Error (Status: ${error.response})`,
        error.response?.data?.message
      );
    }
  };
  const onViewOrder = async (item) => {
    clearNewOrderData();
    setCurrentOrder(item);
    setModalMode("view"); // Set the mode to "view"
    setIsReadOnly(true); // Set the modal to read-only mode

    try {
      const theOrder = await Axios.get(`${SERVER_URL}/get-order/${item._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });

      const data = theOrder.data;

      const updatedItems = data.items.map((item, idx) => {
        const parentSideDishes = item.sideDishes;
        const childSideDishes = item?.id?.sideDishes || [];
        const results = childSideDishes.map((side) => {
          let findSideDish = parentSideDishes.find(
            (p) => p.sideDish === side?._id
          );
          if (findSideDish) {
            return {
              quantity: findSideDish.quantity,
              sideDish: side?._id,
              _id: findSideDish?._id,
            };
          } else {
            return {
              quantity: 0,
              sideDish: side?._id,
              _id: side?._id,
            };
          }
        });
        return {
          ...item,
          sideDishes: results.map((sideDish) => ({
            ...sideDish,
            quantity: sideDish.quantity || 0,
          })),
        };
      });
      setNewOrderData({ ...data, items: updatedItems });
      setOrderModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const onEditOrder = async (item) => {
    clearNewOrderData();
    setCurrentOrder(item);
    setModalMode("edit");
    try {
      const theOrder = await Axios.get(`${SERVER_URL}/get-order/${item._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });

      const data = theOrder.data;

      const updatedItems = data.items.map((item, idx) => {
        const parentSideDishes = item.sideDishes;
        const childSideDishes = item?.id?.sideDishes || [];
        const results = childSideDishes.map((side) => {
          let findSideDish = parentSideDishes.find(
            (p) => p.sideDish === side?._id
          );
          if (findSideDish) {
            return {
              quantity: findSideDish.quantity,
              sideDish: side?._id,
              _id: findSideDish?._id,
            };
          } else {
            return {
              quantity: 0,
              sideDish: side?._id,
              _id: side?._id,
            };
          }
        });
        return {
          ...item,
          sideDishes: results.map((sideDish) => ({
            ...sideDish,
            quantity: sideDish.quantity || 0, // Initialize to 0 if quantity is undefined
          })),
        };
      });
      setNewOrderData({ ...data, items: updatedItems });
      setOrderModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await Axios.delete(`${SERVER_URL}/delete-order/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });
      openNotification("Remove info", "Order removed successfully!");
      setRefetcher((r) => !r);
    } catch (error) {
      console.error(error.message);
      openNotification(
        `Remove error (Status: ${error.response})`,
        error.response?.data?.message
      );
    }
  };

  const clearNewOrderData = () => {
    setNewOrderData({
      customerId: undefined,
      totalPrice: undefined,
      message: "",
      items: [],
      paymentMethod: undefined,
      status: "Pending",
    });
  };

  const getOrders = async () => {
    try {
      const [ordersResponse, customersResponse] = await Promise.all([
        Axios.get(`${SERVER_URL}/orders`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }),
        Axios.get(`${SERVER_URL}/get-all-customers`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }),
      ]);

      const customerData = customersResponse.data.reduce((acc, customer) => {
        acc[customer._id] = customer.email;
        return acc;
      }, {});
      const orderData = ordersResponse.data.map((item, i) => {
        return {
          orderNumber: item.orderNumber,
          customerEmail: customerData[item.customerId]
            ? customerData[item.customerId]
            : item.customerEmail,
          totalPrice: item.discountPrice
            ? parseFloat(item.discountPrice + item.deliveryFee).toFixed(2)
            : parseFloat(item.totalPrice + item.deliveryFee).toFixed(2),
          message: item.message,
          status: item.status,
          // garsonSuccess: item.garsonSuccess ? "DA" : "NE",
          actions: (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <EyeOutlined
                style={{ cursor: "pointer", paddingRight: "8px" }}
                onClick={() => onViewOrder(item)}
              ></EyeOutlined>
              <EditOutlined
                style={{ cursor: "pointer", paddingRight: "8px" }}
                onClick={() => onEditOrder(item)}
              />
              {user?.role !== "dispatcher" && (
                <Popconfirm
                  title="Delete order"
                  description="Are you sure to delete this order?"
                  onConfirm={() => handleDelete(item._id)}
                  okText="Yes"
                  cancelText="No"
                  placement="left"
                >
                  <DeleteOutlined style={{ cursor: "pointer" }} />
                </Popconfirm>
              )}
            </div>
          ),
        };
      });
      setOrders(orderData);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getOrdersByStatus = async () => {
    setLoadingOrdersByStatus(true);
    if (activeTab === "activeOrders") {
      try {
        const { data } = await Axios.get(
          `${SERVER_URL}/orders-by-status/null/null/null`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );

        const processedOrders = data.orders.map((item) => ({
          orderNumber: item.orderNumber,
          customerEmail: item.customerEmail,
          totalPrice: parseFloat(item.totalPrice + item.deliveryFee).toFixed(2),
          message: item.message,
          status: item.status,
          // garsonSuccess: item.garsonSuccess ? "DA" : "NE",
          actions: (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <EyeOutlined
                style={{ cursor: "pointer", paddingRight: "8px" }}
                onClick={() => onViewOrder(item)}
              />
              <EditOutlined
                style={{ cursor: "pointer", paddingRight: "8px" }}
                onClick={() => onEditOrder(item)}
              />
              {user?.role !== "dispatcher" && (
                <Popconfirm
                  title="Delete order"
                  description="Are you sure to delete this order?"
                  onConfirm={() => handleDelete(item._id)}
                  okText="Yes"
                  cancelText="No"
                  placement="left"
                >
                  <DeleteOutlined style={{ cursor: "pointer" }} />
                </Popconfirm>
              )}
            </div>
          ),
        }));

        setOrdersByStatus(processedOrders);
        setOrdersCount(data.totalCount);
        setLoadingOrdersByStatus(false);
      } catch (error) {
        console.error("error getting order by status", error.message);
        setLoadingOrdersByStatus(false);
      }
    } else {
      try {
        const { data } = await Axios.get(
          `${SERVER_URL}/orders-by-status/${activeTab}/10/${currentPage}`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );

        const processedOrders = data.orders.map((item) => ({
          ...item,
          actions: (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <EyeOutlined
                style={{ cursor: "pointer", paddingRight: "8px" }}
                onClick={() => onViewOrder(item)}
              />
            </div>
          ),
        }));

        setOrdersByStatus(processedOrders);
        setLoadingOrdersByStatus(false);
        setOrdersCount(data.totalCount);
      } catch (error) {
        console.error(error.message);
        setLoadingOrdersByStatus(false);
      }
    }
  };

  const handleItemQuantityChange = (itemId, quantity) => {
    setNewOrderData((prevData) => ({
      ...prevData,
      items: prevData.items.map((item) =>
        item._id === itemId ? { ...item, quantity } : item
      ),
    }));
    // Calculate the total price and update the state
    const newTotalPrice = calculateTotalPrice(newOrderData);
    setCalculatedTotalPrice(newTotalPrice);
  };

  const handleSideDishQuantityChange = (itemId, sideDishId, quantity) => {
    setNewOrderData((prevData) => ({
      ...prevData,
      items: prevData.items.map((item) => ({
        ...item,
        sideDishes: item.sideDishes.map((sideDish) =>
          sideDish._id === sideDishId
            ? { ...sideDish, quantity: parseFloat(quantity) }
            : sideDish
        ),
      })),
    }));
    // Calculate the total price and update the state
    const newTotalPrice = calculateTotalPrice(newOrderData);
    setCalculatedTotalPrice(newTotalPrice);
  };

  const calculateTotalPrice = (item) => {
    if (!item) {
      return 0; // Return 0 if the item is undefined
    }
    // Calculate the total price based on the item's properties
    let totalPrice = item.totalPrice || 0; // Initialize with the item's price, default to 0
    if (item.items && Array.isArray(item.items)) {
      for (const product of item.items) {
        totalPrice += (product.id?.price || 0) * (product.quantity || 1);
      }
    }
    // Calculate the total price of side dishes
    if (item.sideDishes && Array.isArray(item.sideDishes)) {
      for (const sideDish of item?.id.sideDishes) {
        totalPrice += (sideDish?.price || 0) * (sideDish.quantity || 1);
      }
    }
    return totalPrice;
  };

  return (
    <>
      {contextHolder}
      <h1>Porudžbine</h1>
      <Row justify={"end"} style={{ paddingBottom: "10px" }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size={"large"}
          onClick={() => {
            setOrderModal(true);
            setModalMode("create");
          }}
        >
          Kreiraj novu
        </Button>
      </Row>
      {loadingOrdersByStatus ? (
        <Skeleton active />
      ) : (
        <Tabs
          activeKey={activeTab}
          defaultActiveKey="sr"
          tabBarStyle={{ marginBottom: "10px" }}
          onChange={handleTabChange}
        >
          <TabPane tab="Active orders" key="activeOrders">
            <Table
              dataSource={ordersByStatus}
              columns={columns}
              size={"small"}
              rowClassName={(record, index) => {
                let classNames = "";
                if (record.status === "Pending") {
                  classNames += "pending-table-row ";
                } else if (record.status === "Cancelled") {
                  classNames += "canceled-table-row ";
                } else if (record.status === "Processing Payment") {
                  classNames += "processing-table-row";
                }

                // ADD WHEN GARSON IS RETURNED
                // if (record.garsonSuccess === 'NE') {
                //   classNames += "garson-failed-row";
                // }

                return classNames.trim();
              }}
            />
          </TabPane>
          <TabPane tab="Delivered" key="Delivered">
            <Table
              dataSource={ordersByStatus}
              columns={columns}
              size={"small"}
              pagination={false}
            />
          </TabPane>
          <TabPane tab="Canceled" key="Cancelled">
            <Table
              dataSource={ordersByStatus}
              columns={columns}
              size={"small"}
              pagination={false}
            />
          </TabPane>
        </Tabs>
      )}

      <>
        <Pagination
          current={currentPage}
          pageSize={10}
          total={ordersCount}
          onChange={handlePageChange}
          showSizeChanger={false}
          style={{
            marginTop: "10px",
            textAlign: "center",
            position: "static",
          }}
        />
      </>

      <Modal
        width={700}
        title={
          modalMode === "create"
            ? "Kreiraj novu porudžbinu"
            : modalMode === "edit"
            ? "Izmeni porudžbinu"
            : "Pregledaj porudžbinu"
        }
        centered
        open={orderModal}
        onOk={
          modalMode === "create"
            ? () => {
                setIsReadOnly(false);
                onOrderCreate();
                clearNewOrderData();
              }
            : modalMode === "edit"
            ? () => {
                setIsReadOnly(false);
                onOrderUpdate();
                clearNewOrderData();
                // window.location.reload();
              }
            : () => {
                // Close the modal in view-only mode
                setOrderModal(false);
                clearNewOrderData();
              }
        }
        onCancel={() => {
          setOrderModal(false);
          setIsReadOnly(false);
          clearNewOrderData();
        }}
      >
        <Row>
          <div style={{ paddingTop: "10px" }}>Šifra porudžbine:</div>
          <label
            style={{
              width: "50%",
              marginLeft: "5px",
              fontWeight: "bold",
              paddingTop: "10px",
            }}
          >
            {newOrderData.orderNumber}
          </label>
          <div style={{ paddingTop: "10px" }}>
            <label>Datum:</label>
            <label
              style={{
                width: "65%",
                fontWeight: "bold",
                paddingTop: "10px",
                marginLeft: "5px",
              }}
            >
              {newOrderData.createdAt &&
                moment(newOrderData.createdAt).format("DD.MM.YYYY. HH:mm")}
              h
            </label>
          </div>
        </Row>
        <Row>
          <div style={{ paddingTop: "10px" }}>Garson:</div>
          <label
            style={{
              width: "31%",
              marginLeft: "5px",
              fontWeight: "bold",
              paddingTop: "10px",
            }}
          >
            {newOrderData.garsonSuccess ? "DA" : "NE"}
          </label>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ width: "40%", paddingBottom: "7px" }}>
              <div>Kupac:</div>
              {!isReadOnly ? (
                <Select
                  style={{ width: "50%", paddingBottom: "7px" }}
                  placeholder="Select customer"
                  value={
                    newOrderData && newOrderData.customerEmail
                      ? newOrderData.customerEmail
                      : newOrderData?.customerId
                  }
                  onChange={(customerId) =>
                    setNewOrderData((prevData) => ({
                      ...prevData,
                      customerId,
                    }))
                  }
                  disabled={isReadOnly}
                >
                  {customersList.map((customer) => (
                    <Select.Option key={customer._id} value={customer._id}>
                      {customer.email}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <label
                  style={{
                    width: "50%",
                    marginLeft: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {newOrderData.customerEmail}
                </label>
              )}
            </div>
            <div style={{ width: "60%", paddingBottom: "7px" }}>
              <label>Adresa:</label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {newOrderData.address &&
                  newOrderData.address.location &&
                  newOrderData.address.location.length > 1 && (
                    <label>
                      Ulica: <strong>{newOrderData.address.location}</strong>
                    </label>
                  )}
                {newOrderData.address &&
                  newOrderData.address.number &&
                  newOrderData.address.number.length > 0 && (
                    <label>
                      Broj: <strong>{newOrderData.address.number}</strong>
                    </label>
                  )}
                {newOrderData.address &&
                  newOrderData.address.floor &&
                  newOrderData.address.floor.length > 0 && (
                    <label>
                      Sprat: <strong>{newOrderData.address.floor}</strong>
                    </label>
                  )}
                {newOrderData.address &&
                  newOrderData.address.entrance &&
                  newOrderData.address.entrance.length > 0 && (
                    <label>
                      Ulaz: <strong>{newOrderData.address.entrance}</strong>
                    </label>
                  )}
                {newOrderData.address &&
                  newOrderData.address.apartmentNumber &&
                  newOrderData.address.apartmentNumber.length > 0 && (
                    <label>
                      Broj stana:{" "}
                      <strong>{newOrderData.address.apartmentNumber}</strong>
                    </label>
                  )}
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div>Kontakt telefon:</div>
          <label
            style={{ width: "50%", marginLeft: "5px", fontWeight: "bold" }}
          >
            {newOrderData.contact}
          </label>
        </Row>
        <Row>
          <div style={{ marginTop: "10px" }}>Proizvodi:</div>
          {!isReadOnly ? (
            <Select
              key={currentOrder?._id}
              style={{ width: "100%", marginTop: "10px" }}
              mode="multiple"
              placeholder="Select items"
              value={newOrderData?.items?.map((item) => item?.title.sr)}
              onChange={async (itemTitles) => {
                // Create a copy of the existing items
                const existingItems = [...newOrderData.items];

                // Iterate through the selected item titles
                for (const itemTitle of itemTitles) {
                  const existingItemIndex = existingItems.findIndex(
                    (item) => item.title.sr === itemTitle
                  );

                  if (existingItemIndex === -1) {
                    // Item is not in the existing list, fetch it from the database
                    try {
                      const productWithSideDishes = await fetchProductByTitle(
                        itemTitle
                      );
                      if (productWithSideDishes) {
                        const newItem = {
                          id: { ...productWithSideDishes },
                          img: productWithSideDishes.img,
                          _id: productWithSideDishes._id,
                          title: { sr: itemTitle },
                          quantity: 1,
                          sideDishes:
                            productWithSideDishes.sideDishes?.map(
                              (sideDish) => ({
                                sideDish: sideDish._id,
                                quantity: 0, // Default sideDish quantity is 0
                                _id: new mongoose.Types.ObjectId(),
                              })
                            ) || [],
                          totalPrice: productWithSideDishes.price || 0, // Set correct price
                        };
                        existingItems.push(newItem);
                      } else {
                        console.error("Failed to fetch product details");
                      }
                    } catch (error) {
                      console.error("Error fetching product details:", error);
                    }
                  }
                }
                // Filter out items that are no longer selected
                const updatedItems = existingItems.filter((item) =>
                  itemTitles.includes(item.title.sr)
                );

                setNewOrderData((prevData) => ({
                  ...prevData,
                  items: updatedItems,
                }));
              }}
            >
              {productsList.map((product) => (
                <Select.Option key={product.title.sr} value={product.title.sr}>
                  {product.title.sr}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <div></div>
          )}
        </Row>
        {newOrderData?.items?.map((item, index) => {
          return (
            <div>
              <div
                key={item._id}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ marginRight: "20px" }}>
                  <div style={{ marginTop: "10px", width: "100%" }}>
                    {item.title.sr}:
                  </div>
                  <Input
                    style={{
                      width: "60px",
                      color: "black",
                      marginBottom: "10px",
                      marginRight: "10px",
                    }}
                    type="number"
                    value={item.quantity}
                    onChange={(e) =>
                      handleItemQuantityChange(item._id, e.target.value)
                    }
                    disabled={isReadOnly}
                  />
                  <span>
                    {item?.id?.price
                      ? +item?.id?.price * item.quantity
                      : +item.totalPrice * item.quantity}{" "}
                    RSD
                  </span>
                </div>
                <div>
                  {item?.id?.sideDishes.length > 0 ? (
                    item?.id?.sideDishes.map((sideDishItem, idx) => {
                      return (
                        <div
                          key={sideDishItem._id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "5px",
                          }}
                        >
                          <div style={{ width: "100px", paddingLeft: "15px" }}>
                            {sideDishItem.title}:
                          </div>
                          <Input
                            style={{ width: "60px", left: "15%" }}
                            type="number"
                            value={item.sideDishes[idx]?.quantity ?? 0}
                            onChange={(e) =>
                              handleSideDishQuantityChange(
                                item?._id,
                                item?.sideDishes[idx]?._id,
                                e.target.value
                              )
                            }
                          />
                          <div>
                            <span
                              style={{
                                marginLeft: "10px",
                                paddingLeft: "80px",
                              }}
                            >
                              {item.quantity * item?.id?.sideDishes[idx].price}{" "}
                              RSD
                            </span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div style={{ fontWeight: "bold" }}>Nema dodataka</div>
                  )}
                </div>
              </div>
              <div style={{ height: "2px", backgroundColor: "#1677ff" }}></div>
            </div>
          );
        })}
        <Row style={{ fontSize: "20px" }}>
          <div>Cena porudžbine:</div>
          <span>{newOrderData.totalPrice} RSD</span>
        </Row>
        {newOrderData.discountPrice != null && (
          <Row style={{ fontSize: "20px" }}>
            <div>Iznos popusta:</div>
            <span>
              {newOrderData.totalPrice - newOrderData.discountPrice} RSD
            </span>
          </Row>
        )}
        {newOrderData.discountPrice != null && (
          <div style={{ height: "1px", backgroundColor: "#282c34" }}></div>
        )}
        {newOrderData.discountPrice != null && (
          <Row style={{ fontSize: "20px" }}>
            <div>Cena sa popustom:</div>
            <span>{newOrderData.discountPrice} RSD</span>
          </Row>
        )}
        <Row style={{ fontSize: "20px" }}>
          <div>Cena dostave:</div>
          <span>{newOrderData.deliveryFee} RSD</span>
        </Row>
        <Row style={{ fontSize: "20px", fontWeight: "bold" }}>
          <div>Ukupna cena:</div>
          <span>
            {newOrderData.discountPrice != null
              ? newOrderData.discountPrice + newOrderData.deliveryFee
              : newOrderData.totalPrice + newOrderData.deliveryFee}{" "}
            RSD
          </span>
        </Row>

        <Row>
          <div>Poruka:</div>
          <TextArea
            style={{ color: "black" }}
            value={newOrderData.message}
            onChange={(e) =>
              setNewOrderData((prevData) => ({
                ...prevData,
                message: e.target.value,
              }))
            }
            disabled={isReadOnly}
          />
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <div>Status porudžbine:</div>
          <Select
            style={{ width: "200px", marginLeft: "10px" }}
            placeholder="Select an order status"
            value={newOrderData.status}
            onChange={(status) =>
              setNewOrderData((prevData) => ({
                ...prevData,
                status,
              }))
            }
            options={[
              { value: "Pending", label: "Čeka se potvrda" },
              { value: "Preparing", label: "U pripremi" },
              { value: "En Route", label: "Na putu" },
              { value: "Delivered", label: "Dostavljeno" },
              { value: "Cancelled", label: "Otkazano" },
            ]}
            className={isReadOnly ? "custom-disabled-select" : ""}
            disabled={isReadOnly}
          />
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <div>Metod plaćanja:</div>
          <Select
            style={{ width: "200px", marginLeft: "10px" }}
            placeholder="Select a payment method"
            value={newOrderData.paymentMethod}
            onChange={(paymentMethod) =>
              setNewOrderData((prevData) => ({
                ...prevData,
                paymentMethod,
              }))
            }
            options={[
              { value: "Credit Card", label: "Online kartica" },
              { value: "Cash on Delivery", label: "Gotovina pouzećem" },
              { value: "Credit Card on Delivery", label: "Kartica pouzećem" },
            ]}
            className={isReadOnly ? "custom-disabled-select" : ""}
            disabled={isReadOnly}
          />
        </Row>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Orders;
