import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  Input,
  Checkbox,
  notification,
  Popconfirm,
  Skeleton,
  Tag,
  Select,
  Tabs,
  Pagination,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import { SERVER_URL } from "../../config";
import { AuthContext } from "../../context/AuthContext";
import TextArea from "antd/es/input/TextArea";

const { TabPane } = Tabs;

const CategoriesDetails = () => {
  const { id } = useParams();
  const [category, setCategory] = useState({});
  const [loading, setLoading] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const { user } = useContext(AuthContext);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [selectedGalleryImage, setSelectedGalleryImage] = useState("");
  const [newCategoryData, setNewCategoryData] = useState({});
  const [categoryModal, setCategoryModal] = useState(false);
  const [refetcher, setRefetcher] = useState();
  const [activeTab, setActiveTab] = useState("sr");
  const [currentPage, setCurrentPage] = useState(1);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  const openNotification = (title, message, placement = "bottomRight") => {
    api.info({
      message: title || "",
      description: message || "",
      placement,
    });
  };

  const getCategory = async () => {
    try {
      const { data } = await Axios.get(
        `${SERVER_URL}/get-single-category/${id}?language=${activeTab}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      setCategory(data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCategory();
  }, [refetcher, activeTab]);

  const fetchGalleryImages = async () => {
    const offset = currentPage * 12 - 12;
    const limit = 12;
    try {
      const response = await Axios.get(
        `${SERVER_URL}/get-images?skip=${offset}&limit=${limit}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      setGalleryImages(response.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchGalleryImages();
  }, [currentPage]);

  const openImageSelectionModal = () => {
    fetchGalleryImages();
    setImageModalVisible(true);
    // Fetch images from the server when the modal is opened
  };

  const handleGalleryImageSelect = (imageURL) => {
    console.log("imageURL", imageURL);
    setSelectedGalleryImage(imageURL);
  };

  const onImageSelectionComplete = () => {
    // Set the selected image for the product
    setNewCategoryData({ ...newCategoryData, img: selectedGalleryImage });

    // Send a PUT request to update the product image URL
    Axios.put(
      `${SERVER_URL}/update-category-image/${id}`, // Use the correct API endpoint
      { originalname: selectedGalleryImage }, // Pass the selected image URL in the request body
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      }
    )
      .then((response) => {
        // Update the product state with the newly updated image URL
        setCategory({ ...category, img: selectedGalleryImage });
        openNotification(
          "Image Update Successful",
          "Product image updated successfully!"
        );
        setRefetcher((r) => !r);
      })
      .catch((error) => {
        console.log(error.message);
        openNotification(
          `Image Update Error (Status: ${error.response.status})`,
          error.response?.data?.message
        );
      });

    setImageModalVisible(false);
  };

  const onEditCategory = (item) => {
    setNewCategoryData({
      title: item.title,
      description: item.description,
    });
    setCategoryModal(true); // Open the modal
  };

  const onUpdateProduct = async () => {
    try {
      const updatedTitle = newCategoryData.title;
      const updatedDescription = newCategoryData.description;

      if (!updatedTitle && !updatedDescription) {
        openNotification(
          "Update Error",
          "Please fill in the title or description."
        );
        return;
      }

      // Send the update only for the "sr" language
      const updateData = {
        title: updatedTitle,
        description: updatedDescription,
        language: "sr",
      };
      const response = await Axios.put(
        `${SERVER_URL}/update-category/${id}`,
        updateData,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      // Update the product state with the newly updated data
      setCategory({ ...category, ...newCategoryData });

      openNotification("Update Successful", "Product updated successfully!");
      setCategoryModal(false); // Close the modal
      setRefetcher((r) => !r); // Refetch products to update the table
    } catch (error) {
      console.log(error.message);
      openNotification(
        `Update Error (Status: ${error.response.status})`,
        error.response?.data?.message
      );
    }
  };

  return (
    <>
      {contextHolder}
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <h1>Kategorija: {category.title}</h1>
          <Tabs
            activeKey={activeTab}
            defaultActiveKey="sr"
            tabBarStyle={{ marginBottom: "10px" }}
            onChange={handleTabChange}
          >
            <TabPane tab="SR" key="sr"></TabPane>
            <TabPane tab="EN" key="en"></TabPane>
            <TabPane tab="DE" key="de"></TabPane>
            <TabPane tab="HU" key="hu"></TabPane>
          </Tabs>
          <Row
            style={{ maxWidth: "1200px", minHeight: "40vh" }}
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col span={8}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={category.img} style={{ width: "100%" }} />
              </div>
            </Col>
            <Col span={16} style={{ paddingLeft: "24px" }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#F6F6F6",
                  borderRadius: "20px",
                  padding: "10px",
                  boxSizing: "border-box",
                }}
              >
                <h3>Category</h3>
                <Row>{category.title}</Row>
                <h3>Description</h3>
                <Row>{category.description}</Row>
                <h3>Products</h3>
                <Row>
                  {category.products.map((c) => {
                    if (c.title) {
                      return (
                        <Tag color="geekblue" style={{ marginBottom: "10px" }}>
                          {c.title}
                        </Tag>
                      );
                    } else {
                      return (
                        <Tag color="red" style={{ marginBottom: "10px" }}>
                          Missing title for selected language
                        </Tag>
                      );
                    }
                  })}
                </Row>
              </div>
            </Col>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={openImageSelectionModal}
            >
              Izmeni sliku
            </Button>
            <Col span={24}>
              <div
                style={{ position: "relative", top: "20px", float: "right" }}
              >
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  size={"large"}
                  onClick={() => onEditCategory(category)}
                >
                  Edit this category
                </Button>
              </div>
            </Col>
          </Row>
        </>
      )}
      <Modal
        title={"Edit product"}
        centered
        open={categoryModal}
        onOk={onUpdateProduct}
        onCancel={() => setCategoryModal(false)}
      >
        <Row>
          <div style={{ marginTop: "10px" }}>Title:</div>
          <Input
            name={"title"}
            placeholder="Title"
            value={newCategoryData?.title}
            onChange={(e) =>
              setNewCategoryData((d) => {
                return { ...d, title: e.target.value };
              })
            }
          />
          <div style={{ marginTop: "10px" }}>Cateogry:</div>

          <div style={{ marginTop: "10px" }}>Description:</div>
          <TextArea
            style={{ height: "180px" }}
            name={"description"}
            placeholder=""
            value={newCategoryData?.description}
            onChange={(e) =>
              setNewCategoryData((d) => {
                return { ...d, description: e.target.value };
              })
            }
          />
        </Row>
      </Modal>
      <Modal
        title="Select an Image"
        centered
        visible={imageModalVisible}
        onOk={onImageSelectionComplete}
        onCancel={() => setImageModalVisible(false)}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxHeight: "400px", // Adjust the max height as needed
            overflowY: "auto",
          }}
        >
          {galleryImages?.images?.map((image) => (
            <div
              key={image._id}
              style={{
                width: "24%",
                height: "120px",
                margin: "4px",
                border: `2px solid ${
                  image.url === selectedGalleryImage ? "blue" : "transparent"
                }`,
              }}
            >
              <img
                src={image.url}
                alt={image.localPath}
                style={{ cursor: "pointer", width: "100%" }}
                onClick={() => handleGalleryImageSelect(image.originalname)}
              />
            </div>
          ))}

          <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
            <Pagination
              current={currentPage}
              pageSize={12}
              total={galleryImages?.imageCount}
              onChange={(page) => handlePageChange(page)}
              showSizeChanger={false} // Optional: Hide page size changer
              style={{
                marginTop: "10px",
                textAlign: "center",
                position: "static",
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CategoriesDetails;
