import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  Input,
  Checkbox,
  notification,
  Popconfirm,
  Skeleton,
  Select,
  Tabs,
  Pagination,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import { SERVER_URL } from "../../config";
import { AuthContext } from "../../context/AuthContext";
import TextArea from "antd/es/input/TextArea";

const { TabPane } = Tabs;

const ProductsDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [refetcher, setRefetcher] = useState();
  const [api, contextHolder] = notification.useNotification();
  const { user } = useContext(AuthContext);
  const [productModal, setProductModal] = useState(false);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [newProductData, setNewProductData] = useState({});
  const [sideDishesList, setSideDishesList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [galleryImages, setGalleryImages] = useState({});
  const [selectedGalleryImage, setSelectedGalleryImage] = useState("");
  const [activeTab, setActiveTab] = useState("sr");
  const [currentPage, setCurrentPage] = useState(1);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const openNotification = (title, message, placement = "bottomRight") => {
    api.info({
      message: title || "",
      description: message || "",
      placement,
    });
  };

  const getProduct = async () => {
    try {
      const { data } = await Axios.get(
        `${SERVER_URL}/get-single-product/${id}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      setSelectedCategory(data.categories[0]?.title[activeTab]);
      setProduct(data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  // Function to fetch all side dishes
  const fetchAllSideDishes = async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/side-dishes`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setSideDishesList(data); // Set the fetched side dishes to the state variable
    } catch (error) {
      console.error(error.message);
    }
  };
  const fetchAllCategories = async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/categories-all`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setCategories(data.categories); // Set the fetched categories to the state variable
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchAllSideDishes();
    fetchAllCategories();
    getProduct();
  }, [refetcher, activeTab]);

  useEffect(() => {
    fetchGalleryImages();
  }, [currentPage]);

  // Function to fetch images from the server
  const fetchGalleryImages = async () => {
    const offset = currentPage * 12 - 12;
    const limit = 12;
    try {
      const response = await Axios.get(
        `${SERVER_URL}/get-images?skip=${offset}&limit=${limit}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      setGalleryImages(response.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const openImageSelectionModal = () => {
    fetchGalleryImages();
    setImageModalVisible(true);
    // Fetch images from the server when the modal is opened
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  const handleGalleryImageSelect = (imageURL) => {
    setSelectedGalleryImage(imageURL);
  };

  const onImageSelectionComplete = () => {
    // Set the selected image for the product
    setNewProductData({ ...newProductData, img: selectedGalleryImage });

    // Send a PUT request to update the product image URL
    Axios.put(
      `${SERVER_URL}/update-product-image/${id}`, // Use the correct API endpoint
      { img: selectedGalleryImage }, // Pass the selected image URL in the request body
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      }
    )
      .then((response) => {
        // Update the product state with the newly updated image URL
        setProduct({ ...product, img: selectedGalleryImage });
        openNotification(
          "Image Update Successful",
          "Product image updated successfully!"
        );
        setRefetcher((r) => !r);
      })

      .catch((error) => {
        console.log(error.message);
        openNotification(
          `Image Update Error (Status: ${error.response.status})`,
          error.response?.data?.message
        );
      });

    setImageModalVisible(false);
  };

  const onEditProduct = (item) => {
    setSelectedImage(item.img);
    setNewProductData({
      title: item.title[activeTab],
      categories: item.categories[0],
      description: item.description[activeTab],
      img: item.img,
      sideDishes: item.sideDishes,
      maxSideDishes: item.maxSideDishes,
      minSideDishes: item.minSideDishes,
      minutes: item.minutes,
      price: item.price,
      available: item.available,
      recommended: item.recommended,
    });
    setProductModal(true); // Open the modal
  };

  const onUpdateProduct = async () => {
    try {
      const updatedTitle = newProductData.title;
      const updatedDescription = newProductData.description;
      if (!updatedTitle || !updatedDescription) {
        openNotification(
          "Update Error",
          "Please fill in the title or description."
        );
        return;
      }

      // Send the update only for the "sr" language
      const updateData = {
        title: updatedTitle,
        description: updatedDescription,
        language: activeTab,
        available: newProductData.available,
        recommended: newProductData.recommended,
        categories: newProductData.categories,
        sideDishes: newProductData.sideDishes,
        minSideDishes: newProductData.minSideDishes,
        maxSideDishes: newProductData.maxSideDishes,
      };
      const response = await Axios.put(
        `${SERVER_URL}/update-product/${id}`,
        updateData,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      // Update the product state with the newly updated data
      setProduct({ ...product, ...newProductData });

      openNotification("Update Successful", "Product updated successfully!");
      setProductModal(false); // Close the modal
      setRefetcher((r) => !r); // Refetch products to update the table
    } catch (error) {
      console.log(error.message);
      openNotification(
        `Update Error (Status: ${error.response.status})`,
        error.response?.data?.message
      );
    }
  };

  return (
    <>
      {contextHolder}
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <h1>Proizvod: {product.title[activeTab]}</h1>
          <Tabs
            activeKey={activeTab}
            defaultActiveKey="sr"
            tabBarStyle={{ marginBottom: "10px" }}
            onChange={handleTabChange}
          >
            <TabPane tab="SR" key="sr"></TabPane>
            <TabPane tab="EN" key="en"></TabPane>
            <TabPane tab="DE" key="de"></TabPane>
            <TabPane tab="HU" key="hu"></TabPane>
          </Tabs>
          <Row
            style={{ maxWidth: "1200px", minHeight: "40vh" }}
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col span={8}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={product.img} style={{ width: "100%" }} />
              </div>
            </Col>

            <Col span={16} style={{ paddingLeft: "24px" }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#F6F6F6",
                  borderRadius: "20px",
                  padding: "10px",
                  boxSizing: "border-box",
                }}
              >
                <h3>Product</h3>
                <Row>
                  {product && product.title && product.title[activeTab]
                    ? product.title[activeTab]
                    : ""}
                </Row>
                <h3>Description</h3>
                <Row>
                  {product &&
                  product.description &&
                  product.description[activeTab]
                    ? product.description[activeTab]
                    : ""}
                </Row>
                <h3>Categories</h3>
                {product &&
                  product.categories &&
                  product.categories[0]?.title &&
                  product.categories[0]?.title[activeTab] && (
                    <Row>{product.categories[0]?.title[activeTab]}</Row>
                  )}
                <h3>Side dishes</h3>
                <Row>{product.sideDishes.map((c) => c.title).join(", ")}</Row>
                <h3>Price</h3>
                <Row>RSD {parseFloat(product.price).toFixed(2)}</Row>
                <h3>Available:</h3>
                <Row>{product.available === true ? "Yes" : "No"}</Row>
                <h3>Recommended:</h3>
                <Row>{product.recommended === true ? "Yes" : "No"}</Row>
              </div>
            </Col>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={openImageSelectionModal}
            >
              Izmeni sliku
            </Button>
            <Col span={24}>
              <div
                style={{ position: "relative", top: "20px", float: "right" }}
              >
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  size={"large"}
                  onClick={() => onEditProduct(product)}
                >
                  Izmeni ovaj proizvod
                </Button>
              </div>
            </Col>
          </Row>
        </>
      )}
      <Modal
        title={"Edit product"}
        centered
        open={productModal}
        onOk={onUpdateProduct}
        onCancel={() => setProductModal(false)}
      >
        <Row>
          <div style={{ marginTop: "10px" }}>Title:</div>
          <Input
            name={"title"}
            placeholder="Title"
            value={newProductData?.title}
            onChange={(e) =>
              setNewProductData((d) => {
                return { ...d, title: e.target.value };
              })
            }
          />
          <div style={{ marginTop: "10px" }}>Category:</div>

          <Select
            style={{ width: "100%" }}
            placeholder="Select a category"
            value={selectedCategory}
            onChange={(value) => {
              setSelectedCategory(value); // Set the selected category title
              const selectedCategoryId = categories.find(
                (category) => category.title[activeTab] === value
              )._id;
              setNewProductData((data) => ({
                ...data,
                categories: [selectedCategoryId], // Set the selected category ID
              }));
            }}
          >
            {categories.map((category) => (
              <Select.Option
                key={category._id}
                value={category.title[activeTab]}
              >
                {category.title[activeTab]}
              </Select.Option>
            ))}
          </Select>

          <div style={{ marginTop: "10px" }}>Description:</div>
          <TextArea
            style={{ height: "180px" }}
            name={"description"}
            placeholder=""
            value={newProductData?.description}
            onChange={(e) =>
              setNewProductData((d) => {
                return { ...d, description: e.target.value };
              })
            }
          />

          {/* <div style={{ marginTop: "10px" }}>Side dishes:</div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            value={newProductData?.sideDishes?.map((dish) => dish?.title)}
            onChange={(selectedSideDishes) => {
              const selectedSideDishObjects = selectedSideDishes.map(
                (title) => {
                  const existingSideDish = sideDishesList.find(
                    (dish) => dish.title === title
                  );
                  return existingSideDish || { title };
                }
              );

              setNewProductData((d) => ({
                ...d,
                sideDishes: selectedSideDishObjects,
              }));
            }}
          >
            {sideDishesList?.map((sideDish) => (
              <Select.Option key={sideDish.id} value={sideDish.title}>
                {sideDish.title}
              </Select.Option>
            ))}
          </Select>
          <Row style={{ paddingTop: "10px" }}>
            <div
              style={{ marginTop: "10px", width: "110px", paddingLeft: "4px" }}
            >
              {" "}
              Max side dishes:{" "}
            </div>
            <Input
              style={{ width: "100px" }}
              name={"maxSideDishes"}
              placeholder="Max side dishes"
              value={newProductData?.maxSideDishes}
              onChange={(e) =>
                setNewProductData((d) => {
                  return { ...d, maxSideDishes: e.target.value };
                })
              }
            />

            <div
              style={{ marginTop: "10px", width: "110px", paddingLeft: "4px" }}
            >
              {" "}
              Min side dishes:{" "}
            </div>
            <Input
              style={{ width: "100px" }}
              name={"minSideDishes"}
              placeholder="Min side dishes"
              value={newProductData?.minSideDishes}
              onChange={(e) =>
                setNewProductData((d) => {
                  return { ...d, minSideDishes: e.target.value };
                })
              }
            />
          </Row> */}

          <Row style={{ paddingTop: "10px" }}>
            <div style={{ marginTop: "10px", width: "50px" }}>Price:</div>
            <Input
              disabled
              style={{ width: "140px" }}
              name={"price"}
              placeholder="Price"
              value={newProductData?.price}
              onChange={(e) =>
                setNewProductData((d) => {
                  return { ...d, price: e.target.value };
                })
              }
            />
          </Row>
          <div>
            <Checkbox
              style={{ marginTop: "10px", paddingLeft: "5px" }}
              checked={newProductData?.available}
              onChange={(e) =>
                setNewProductData((d) => {
                  return { ...d, available: e.target.checked };
                })
              }
            >
              Available
            </Checkbox>

            <Checkbox
              style={{ marginTop: "10px" }}
              checked={newProductData?.recommended}
              onChange={(e) =>
                setNewProductData((d) => {
                  return { ...d, recommended: e.target.checked };
                })
              }
            >
              Recommended
            </Checkbox>
          </div>
        </Row>
      </Modal>
      <Modal
        title="Select an Image"
        centered
        visible={imageModalVisible}
        onOk={onImageSelectionComplete}
        onCancel={() => setImageModalVisible(false)}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxHeight: "400px", // Adjust the max height as needed
            overflowY: "auto",
          }}
        >
          {galleryImages?.images?.map((image) => (
            <div
              key={image._id}
              style={{
                width: "30%",
                // height: "120px",
                margin: "4px",
                border: `2px solid ${
                  image.originalname === selectedGalleryImage
                    ? "#1677ff"
                    : "transparent"
                }`,
              }}
            >
              <img
                src={image.url}
                alt={image.localPath}
                style={{ cursor: "pointer", width: "100%", height: "100%" }}
                onClick={() => handleGalleryImageSelect(image.originalname)}
              />
            </div>
          ))}
          <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
            <Pagination
              current={currentPage}
              pageSize={12}
              total={galleryImages?.imageCount}
              onChange={(page) => handlePageChange(page)}
              showSizeChanger={false} // Optional: Hide page size changer
              style={{
                marginTop: "10px",
                textAlign: "center",
                position: "static",
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProductsDetails;
