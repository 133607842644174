import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Fetch user data from API or local storage
    const fetchUser = async () => {
      // Example user data
      const userData = {
        username: "editorUser",
        role: "editor", // this can be "admin", "editor", etc.
      };
      setUser(userData);
    };

    fetchUser();
  }, []);

  const logout = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, logout }}>
      {children}
    </AuthContext.Provider>
  );
};