// Core
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { SocketProvider } from './socketContext';

// Context
import { AuthContext } from './context/AuthContext';

// Style
import './App.css';
import LoginPage from './pages/auth/Login';
import Dashboard from './pages/dashboard/Dashboard';
import { BrowserRouter } from 'react-router-dom';

// Components
import { Button, Divider, Space, notification } from 'antd';
import { SERVER_URL } from './config';

const App = () => {

  const [user, setUser] = useState();
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (title, message, placement = 'bottomRight') => {
    api.info({
      message: title || '',
      description: message || '',
      placement
    });
  };

  const login = async ({ email, password }) => {
    try {
      console.log(email, password, SERVER_URL)
      const { data } = await Axios.post(`${SERVER_URL}/admin-login`, { email, password }, { withCredentials: false });
      openNotification("Loggin info", "Login successfully!");
      sessionStorage.setItem('user', JSON.stringify(data));
      setUser(data);

    } catch (error) {
      openNotification("Login error", error.message);
    }
  }

  const logout = () => {
    sessionStorage.removeItem('user');
    setUser();
    window.location.assign('/');
  }

  useEffect(() => {
    const sessionUser = JSON.parse(sessionStorage.getItem('user'));
    setUser(sessionUser);
  }, [])

  if (user) {
    return (
      <SocketProvider>
        <AuthContext.Provider value={{ user, logout }}>
          <BrowserRouter>
            <Dashboard />
          </BrowserRouter>
        </AuthContext.Provider>
      </SocketProvider>
    )
  } else {
    return (
      <>
        {contextHolder}
        <LoginPage login={login} />
      </>
    )
  }
}

export default App;
