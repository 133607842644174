// Core
import React, { useState } from 'react';

// Components
import { Card, Button, Input } from 'antd';

const LoginPage = ({ login }) => {

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  return (
    <div style={{ display: 'flex',flexDirection:'column', gap:'30px',alignItems:'center', justifyContent: 'center', marginTop: '20vh' }}>
      <div>
        <img src='/basch-house-logo.png' alt='OK'></img>
      </div>
      <Card title="Basch House Admin Login" bordered={true} style={{ textAlign: 'center', width: 400 }}>
        <p>Email</p>
        <Input placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
        <p style={{ marginTop: '40px' }}>Password</p>
        <Input.Password placeholder="Password" visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }} onChange={(e) => setPassword(e.target.value)} />
        <Button type="primary" style={{ width: '100%', marginTop: '20px' }} onClick={() => login({ email, password })}>Login</Button>
      </Card>
    </div>
  )
}

export default LoginPage;