import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  Row,
  Button,
  Modal,
  Input,
  Checkbox,
  notification,
  Skeleton,
  Form,
  DatePicker,
} from "antd";
import {
  ExportOutlined,
  MessageOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import { SERVER_URL } from "../../config";
import { AuthContext } from "../../context/AuthContext";

const { TextArea, Search } = Input;
const { RangePicker } = DatePicker;

const initialValues = {
  message: undefined,
};

const Users = () => {
  const [users, setusers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [refetcher, setRefetcher] = useState();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const [api, contextHolder] = notification.useNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const [filteredDate, setFilteredDate] = useState([]);
  const [formattedDate, setFormattedDate] = useState([]);

  console.log("usersusers", users);

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      align: "center",
    },
    {
      title: "Korisnik",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Kreiran",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (timestamp) => new Date(timestamp).toLocaleString(),
    },
    {
      title: "Poslednje logovanje",
      dataIndex: "lastOpen",
      key: "lastOpen",
      align: "center",
    },
    {
      title: "Broj telefona",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: "center",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Phone"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Pretraži
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Poništi
          </Button>
        </div>
      ),
      onFilter: (value, record) => record.phoneNumber.includes(value),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Email"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Pretraži
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Poništi
          </Button>
        </div>
      ),
      onFilter: (value, record) =>
        record.email
          ? record.email.toLowerCase().includes(value.toLowerCase())
          : false,
    },
    {
      title: "Ukupna suma",
      dataIndex: "totalOrdersSum",
      key: "totalOrdersSum",
      align: "center",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <RangePicker
            value={selectedKeys[0]}
            onChange={(date) => {
              setSelectedKeys(date ? [date] : []);
            }}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button
            onClick={() => {
              confirm();
              // setFilteredDate(selectedKeys[0]);
              filterSumByDate(selectedKeys[0]);
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <span style={{ color: filtered ? "#1890ff" : undefined }}>📅</span>
      ),
      // onFilter: (value, record) => {
      //   const recordDate = new Date(record.date).setHours(0, 0, 0, 0);
      //   const filterDate = value ? value.setHours(0, 0, 0, 0) : null;
      //   return recordDate === filterDate;
      // },
      // filteredValue: filteredDate ? [filteredDate] : null,
    },
  ];

  const filterSumByDate = (date) => {
    if (date) {
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDateFrom = new Intl.DateTimeFormat(
        "en-GB",
        options
      ).format(date[0]);

      const formattedDateTo = new Intl.DateTimeFormat("en-GB", options).format(
        date[1]
      );

      const formattedFrom = formattedDateFrom.replace(/\//g, "-");
      const formattedTo = formattedDateTo.replace(/\//g, "-");

      setFormattedDate([formattedFrom, formattedTo]);
    } else {
      setFormattedDate([]);
    }
  };

  const openNotification = (title, message, placement = "bottomRight") => {
    api.info({
      message: title || "",
      description: message || "",
      placement,
    });
  };

  const getusers = async () => {
    let axiosRoute;

    if (formattedDate.length > 0) {
      axiosRoute = `get-users-with-order-sum?from=${formattedDate[0]}&to=${formattedDate[1]}`;
    } else {
      axiosRoute = "get-users-with-order-sum";
    }

    try {
      const { data } = await Axios.get(`${SERVER_URL}/${axiosRoute}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });
      const itemData = data.map((item, i) => {
        return {
          key: i + 1,
          _id: item._id,
          name: item.name,
          createdAt: item.createdAt,
          phoneNumber: item.phoneNumber,
          email: item.email,
          lastOpen: item?.lastOpen,
          totalOrdersSum: item?.totalOrdersSum,
        };
      });
      setusers(itemData);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getusers();
  }, [refetcher, formattedDate]);

  const handleExportPhoneNumbers = async () => {
    try {
      // Make a request to your backend to get the CSV data
      const response = await Axios.get(`${SERVER_URL}/export-phone-numbers`, {
        responseType: "blob", // Ensure the response is treated as a blob
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });

      // Check if the response contains data
      if (response.data) {
        // Create a Blob from the response data with CSV MIME type
        const blob = new Blob([response.data], { type: "text/csv" });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = "phoneNumbers.csv"; // Specify the default filename

        // Trigger the download
        a.click();

        // Clean up the URL object
        window.URL.revokeObjectURL(url);

        // Show a notification to indicate success
        openNotification(
          "Phone Numbers Exported",
          "Phone numbers have been exported successfully."
        );
      } else {
        // Handle the case where the response is empty
        console.error("Empty response data");
      }
    } catch (error) {
      console.error(error.message);
      openNotification(
        "Export Failed",
        "Failed to export Phone numbers.",
        "topRight"
      );
    }
  };

  const handleExportEmails = async () => {
    try {
      // Make a request to your backend to get the CSV data
      const response = await Axios.get(`${SERVER_URL}/export-emails`, {
        responseType: "blob", // Ensure the response is treated as a blob
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });

      // Check if the response contains data
      if (response.data) {
        // Create a Blob from the response data with CSV MIME type
        const blob = new Blob([response.data], { type: "text/csv" });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = "emailAddresses.csv"; // Specify the default filename

        // Trigger the download
        a.click();

        // Clean up the URL object
        window.URL.revokeObjectURL(url);

        // Show a notification to indicate success
        openNotification(
          "Email addresses Exported",
          "Email addresses have been exported successfully."
        );
      } else {
        // Handle the case where the response is empty
        console.error("Empty response data");
      }
    } catch (error) {
      console.error(error.message);
      openNotification(
        "Export Failed",
        "Failed to export Email addresses.",
        "topRight"
      );
    }
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const handleSelect = (record, selected) => {
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record._id]);
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record._id);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
    }
  };

  const toggleSelectAll = () => {
    setSelectedRowKeys((keys) =>
      keys.length === users.length ? [] : users.map((r) => r._id)
    );
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length}
      indeterminate={
        selectedRowKeys.length > 0 && selectedRowKeys.length < users.length
      }
      onChange={toggleSelectAll}
    />
  );

  const rowSelection = {
    selectedRowKeys,
    type: "checkbox",
    fixed: true,
    onSelect: handleSelect,
    columnTitle: headerCheckbox,
    //onSelectAll: this.handleSelectAll
  };

  const handleOk = async () => {
    const message = form.getFieldValue("message");
    const title = form.getFieldValue("messageTitle");

    const selectedUsers = users.filter((user) =>
      selectedRowKeys.includes(user._id)
    );

    const emails = selectedUsers.map((user) => user.email);
    const data = {
      data: {
        emails: emails,
        title: title,
        body: message,
      },
    };

    try {
      const response = await Axios.post(
        `${SERVER_URL}/test-user-notification`,
        data,
        {
          withCredentials: false,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    } catch (error) {
      console.log(error.message);
    }

    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSearch = async (value) => {
    // If the search value is empty, display all users
    if (!value.trim()) {
      setFilteredUsers(users);
      return;
    }

    // Convert the search value to lowercase for case-insensitive search
    const searchTerm = value?.toLowerCase();

    // Filter users based on the search term
    const filteredUsers = users.filter((user) => {
      return user?.name?.toLowerCase().includes(searchTerm);
    });

    // Process the filtered results and update the state
    const itemData = filteredUsers.map((item, i) => {
      return {
        key: i + 1,
        _id: item._id,
        name: item.name,
        createdAt: item.createdAt,
        phoneNumber: item.phoneNumber,
        email: item.email,
        lastOpen: item?.lastOpen,
        totalOrdersSum: item?.totalOrdersSum,
      };
    });

    setFilteredUsers(itemData);
  };

  return (
    <div>
      {contextHolder}
      <div style={{ display: "flex", alignItems: "center", gap: "50px" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <h1>Korisnici</h1>
          <Search
            placeholder="Pretraži korisnike"
            style={{ width: 200 }}
            // onChange={(e) => handleSearch(e)}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>

        {/* <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <h1>Sume usera po datumu</h1>
          <RangePicker />
        </div> */}
      </div>
      <Row justify={"end"} style={{ paddingBottom: "10px" }}>
        <Button
          type="primary"
          icon={<MessageOutlined />}
          size={"large"}
          onClick={setIsModalOpen}
        >
          Pošalji poruku korisnicima
        </Button>
        <Button
          style={{ marginLeft: "20px" }}
          type="primary"
          icon={<ExportOutlined />}
          size={"large"}
          onClick={handleExportPhoneNumbers}
        >
          Eksportuj brojeve
        </Button>
        <Button
          style={{ marginLeft: "20px" }}
          type="primary"
          icon={<ExportOutlined />}
          size={"large"}
          onClick={handleExportEmails}
        >
          Eksportuj e-mailove
        </Button>
      </Row>
      {loading ? (
        <Skeleton active />
      ) : (
        <Table
          dataSource={filteredUsers.length > 0 ? filteredUsers : users}
          columns={columns}
          size={"small"}
          rowSelection={rowSelection}
          rowKey={(record) => record._id}
        />
      )}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        okText="Send"
        onCancel={handleCancel}
        style={{ top: 40 }}
        // okButtonProps={modalType === 'reject' ? { disabled: !inputContent.length } : {}}
      >
        <h2 style={{ paddingTop: "10px" }}>Upisite poruku</h2>
        <Form form={form} initialValues={initialValues} layout="vertical">
          {/* {modalType === 'reject' && ( */}

          <Form.Item name="messageTitle" label="Title">
            <Input />
          </Form.Item>
          <Form.Item name="message" label="Message">
            <TextArea />
          </Form.Item>
          {/* )} */}
        </Form>
      </Modal>
    </div>
  );
};

export default Users;
