import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Row,
  Button,
  Modal,
  Input,
  Checkbox,
  notification,
  Popconfirm,
  Skeleton,
  Select,
  Tabs,
  Tag,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
  EditOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import { SERVER_URL } from "../../config";
import { AuthContext } from "../../context/AuthContext";
import TextArea from "antd/es/input/TextArea";

const { Search } = Input;

const Products = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [refetcher, setRefetcher] = useState();
  const [loading, setLoading] = useState(true);
  const [productModal, setProductModal] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [imageView, setImageView] = useState(false);
  const [newProductData, setNewProductData] = useState({});
  const { user } = useContext(AuthContext);
  const [api, contextHolder] = notification.useNotification();
  const [currentProduct, setCurrentProduct] = useState();
  const [sideDishesList, setSideDishesList] = useState([]);
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("sr");

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      align: "center",
    },
    {
      title: activeTab === "sr" ? "Fotografija" : "Image",
      dataIndex: "img",
      align: "center",
    },
    {
      title: activeTab === "sr" ? "Proizvod" : "Product",
      dataIndex: "product",
      key: "product",
      align: "center",
    },
    {
      title: activeTab === "sr" ? "Kategorija" : "Category",
      dataIndex: "categories",
      align: "center",
    },
    {
      title: activeTab === "sr" ? "Opis" : "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: activeTab === "sr" ? "Dostupno" : "Available",
      dataIndex: "available",
      key: "available",
      align: "center",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      align: "center",
    },
  ];

  const openNotification = (title, message, placement = "bottomRight") => {
    api.info({
      message: title || "",
      description: message || "",
      placement,
    });
  };

  const onImageView = (item) => {
    setCurrentProduct(item);
    setImageView(true);
  };

  // Function to fetch all side dishes
  const fetchAllSideDishes = async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/side-dishes`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setSideDishesList(data); // Set the fetched side dishes to the state variable
    } catch (error) {
      console.error(error.message);
    }
  };

  const onEditProduct = (item) => {
    setCurrentProduct(item);
    setModalMode("edit"); // Set modal mode to 'edit'
    setNewProductData({
      title: item.title[activeTab],
      categories: item.categories,
      description: item.description[activeTab],
      img: item.img,
      sideDishes: item.sideDishes,
      maxSideDishes: item.maxSideDishes,
      minSideDishes: item.minSideDishes,
      minutes: item.minutes,
      price: item.price,
      available: item.available,
      recommended: item.recommended,
      language: activeTab,
    });
    setProductModal(true); // Open the modal
  };

  const getCategories = useCallback(async () => {
    try {
      const { data } = await Axios.get(
        `${SERVER_URL}/categories-all-title-list?language=${activeTab}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      setCategoriesList(data.categories);
      return data;
    } catch (error) {
      console.error(error.message);
    }
  }, [activeTab, user.token]);

  const getCategoryId = (title) => {
    const findCategoryByName = categoriesList.find(
      (item) => item.title === title
    );

    if (findCategoryByName) {
      return findCategoryByName._id;
    }

    return null;
  };

  const handleSearch = async (value) => {
    try {
      const response = await Axios.get(
        `${SERVER_URL}/search-products?q=${value}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      const productsData = response.data.map((product) => {
        const categoriesFiltered = product.categories.map((category) => {
          return categories.find((cat) => cat._id === category);
        });

        if (categoriesFiltered && categoriesFiltered.length) {
          return {
            ...product,
            categories: categoriesFiltered.map(
              (categoryTitle) => categoryTitle.title
            ),
          };
        }

        return product;
      });

      setProducts(productsData);
    } catch (error) {
      console.error(error.message);
    }
  };

  const prodMemo = useMemo(() => {
    return products.map((prod, i) => {
      return {
        key: i + 1,
        img: (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={prod.img}
              style={{
                maxWidth: "50px",
                maxHeight: "50px",
                cursor: "pointer",
              }}
              alt=""
              onClick={() => onImageView(prod)}
            />
          </div>
        ),
        product: (
          <Link to={`/products/details/${prod._id}`}>
            {prod.title[activeTab] || "No title for selected language"}
          </Link>
        ),
        categories: prod.categories,
        description:
          prod.description?.length > 100
            ? `${prod.description[activeTab].slice(0, 100)}...`
            : prod.description[activeTab] || "No title for selected language",
        available: prod.available ? (
          <Tag color="green">
            <CheckOutlined />
          </Tag>
        ) : (
          <Tag color="red">
            <CloseOutlined />
          </Tag>
        ),
        actions: (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <EditOutlined
              style={{ cursor: "pointer", paddingRight: "8px" }}
              onClick={() => onEditProduct(prod)}
            />
            <Popconfirm
              title="Delete a product"
              description="Are you sure to delete this product?"
              onConfirm={() => handleDelete(prod._id)}
              okText="Yes"
              cancelText="No"
              placement="left"
            >
              <DeleteOutlined style={{ cursor: "pointer" }} />
            </Popconfirm>
          </div>
        ),
      };
    });
  }, [products, activeTab]);

  const getProducts = useCallback(async () => {
    try {
      setLoading(true);
      const categ = await getCategories();
      setCategories(categ.categories);
      const { data } = await Axios.get(`${SERVER_URL}/products-all`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });

      const productsData = data.map((product) => {
        const categoriesFiltered = product.categories.map((category) => {
          return categ.categories.find((cat) => cat._id === category);
        });

        if (categoriesFiltered && categoriesFiltered.length) {
          return {
            ...product,
            categories: categoriesFiltered.map((categoryTitle) =>
              categoryTitle && categoryTitle.title ? categoryTitle.title : ""
            ),
          };
        }

        return product;
      });
      setProducts(productsData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error.message);
    }
  }, [activeTab, user.token]);

  useEffect(() => {
    fetchAllSideDishes();
    getProducts();
  }, [activeTab, refetcher]);

  const onUpdateProduct = useCallback(async () => {
    try {
      // Determine the language based on the active tab
      const language = activeTab;
      // Send the update only for the "sr" language
      let updateData = {
        title: newProductData.title,
        description: newProductData.description,
        language: [activeTab],
        available: newProductData.available,
        recommended: newProductData.recommended,
        categories: [newProductData.categories],
        sideDishes: newProductData.sideDishes,
      };

      const checkName = getCategoryId(newProductData.categories.toString());

      if (checkName) {
        updateData = {
          ...updateData,
          categories: [checkName],
        };
      }
      await Axios.put(
        `${SERVER_URL}/update-product/${currentProduct._id}`,
        updateData,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      openNotification("Update Successful", "Product updated successfully!");
      setProductModal(false); // Close the modal
      setRefetcher((r) => !r); // Refetch products to update the table
    } catch (error) {
      console.error(error.message);
      openNotification(
        `Update Error (Status: ${error.response})`,
        error.response?.data?.message
      );
    }
  }, [newProductData, activeTab]);
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const onProductCreate = async () => {
    setModalMode("create");
    try {
      // Check if newProductData contains the required data
      if (!newProductData.title || !newProductData.description) {
        openNotification(
          "Validation Error",
          "Please fill in the required fields."
        );
        return;
      }
      const language = activeTab;
      const response = await Axios.post(
        `${SERVER_URL}/create-product`,
        { ...newProductData, language },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      if (response.status === 201) {
        openNotification("Create Successful", "Product created successfully!");
        setProductModal(false); // Close the modal
        setNewProductData({}); // Clear the form fields
        setRefetcher((r) => !r);
      } else {
        openNotification("Create Error", "Failed to create the product.");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await Axios.delete(
        `${SERVER_URL}/delete-product/${id}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      openNotification("Remove info", "Product removed successfully!");
      setRefetcher((r) => !r);
    } catch (error) {
      console.error(error.message);
      openNotification(
        `Remove error (Status: ${error.response})`,
        error.response?.data?.message
      );
    }
  };
  return (
    <>
      {contextHolder}
      <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
        <h1>{activeTab === "sr" ? "Proizvodi" : "Products"}</h1>
        <Search
          onSearch={handleSearch}
          placeholder="Pretraži proizvode"
          style={{ width: 200 }}
        />
      </div>

      <Row justify={"end"} style={{ paddingBottom: "10px" }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size={"large"}
          onClick={() => {
            setProductModal(true);
            setModalMode("create");
          }}
        >
          {activeTab === "sr" ? "Kreiraj novi" : "Create new"}
        </Button>
      </Row>
      {loading ? (
        <Skeleton active />
      ) : (
        <Tabs
          activeKey={activeTab}
          defaultActiveKey="sr"
          tabBarStyle={{ marginBottom: "10px" }}
          onChange={handleTabChange}
        >
          <TabPane tab="SR" key="sr">
            <Table dataSource={prodMemo} columns={columns} size={"small"} />
          </TabPane>
          <TabPane tab="EN" key="en">
            <Table dataSource={prodMemo} columns={columns} size={"small"} />
          </TabPane>
          <TabPane tab="DE" key="de">
            <Table dataSource={prodMemo} columns={columns} size={"small"} />
          </TabPane>
          <TabPane tab="HU" key="hu">
            <Table dataSource={prodMemo} columns={columns} size={"small"} />
          </TabPane>
        </Tabs>
      )}
      <Modal
        title={modalMode === "create" ? "Create a new product" : "Edit product"}
        centered
        open={productModal}
        onOk={
          modalMode === "create"
            ? () => {
              onProductCreate();
            }
            : () => onUpdateProduct()
        }
        onCancel={() => setProductModal(false)}
      >
        <Row>
          <div style={{ marginTop: "10px" }}>Title:</div>
          <Input
            name={"title"}
            placeholder="Title"
            value={newProductData?.title}
            onChange={(e) =>
              setNewProductData((d) => {
                return { ...d, title: e.target.value };
              })
            }
          />

          <div style={{ marginTop: "10px" }}>Category:</div>
          <Select
            style={{ width: "100%" }}
            placeholder="Select a category"
            value={newProductData?.categories} // Set the selected category
            onChange={(value) =>
              setNewProductData((d) => ({ ...d, categories: value }))
            } // Update selected category
          >
            {categoriesList.map((category) => (
              <Select.Option key={category._id} value={category._id}>
                {category?.title}
              </Select.Option>
            ))}
          </Select>

          <div style={{ marginTop: "10px" }}>Description:</div>
          <TextArea
            style={{ height: "180px" }}
            name={"description"}
            placeholder=""
            value={newProductData?.description}
            onChange={(e) =>
              setNewProductData((d) => {
                return { ...d, description: e.target.value };
              })
            }
          />

          {/* <div style={{ marginTop: "10px" }}>Side dishes:</div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            value={newProductData?.sideDishes?.map((dish) => dish?.title)}
            onChange={(selectedSideDishes) => {
              const selectedSideDishObjects = selectedSideDishes.map(
                (title) => {
                  const existingSideDish = sideDishesList.find(
                    (dish) => dish.title === title
                  );
                  return existingSideDish || { title };
                }
              );

              setNewProductData((d) => ({
                ...d,
                sideDishes: selectedSideDishObjects,
              }));
            }}
          >
            {sideDishesList?.map((sideDish) => (
              <Select.Option key={sideDish._id} value={sideDish.title}>
                {sideDish.title}
              </Select.Option>
            ))}
          </Select> */}

          <div style={{ marginTop: "10px" }}>Price:</div>
          <Input
            disabled
            name={"price"}
            placeholder="Price"
            value={newProductData?.price}
            onChange={(e) =>
              setNewProductData((d) => {
                return { ...d, price: e.target.value };
              })
            }
          />

          <div style={{ marginTop: "10px" }}>Preparing time:</div>
          <Input
            name={"minutes"}
            placeholder="Preparing time"
            value={newProductData?.minutes}
            onChange={(e) =>
              setNewProductData((d) => {
                return { ...d, minutes: e.target.value };
              })
            }
          />
          <div>
            <Checkbox
              style={{ marginTop: "10px" }}
              checked={newProductData?.available}
              onChange={(e) =>
                setNewProductData((d) => {
                  return { ...d, available: e.target.checked };
                })
              }
            >
              Available
            </Checkbox>

            <Checkbox
              style={{ marginTop: "10px" }}
              checked={newProductData?.recommended}
              onChange={(e) =>
                setNewProductData((d) => {
                  return { ...d, recommended: e.target.checked };
                })
              }
            >
              Recommended
            </Checkbox>
          </div>
        </Row>
      </Modal>
    </>
  );
};

export default Products;
