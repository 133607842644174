import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Row,
  Button,
  Modal,
  Input,
  Checkbox,
  notification,
  Popconfirm,
  Skeleton,
  Form,
} from "antd";
import {
  PlusOutlined,
  ExportOutlined,
  DeleteOutlined,
  LoadingOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import { SERVER_URL } from "../../config";
import { AuthContext } from "../../context/AuthContext";

const { TextArea } = Input;

const columns = [
  {
    title: "#",
    dataIndex: "key",
    align: "center",
  },
  {
    title: "Ime",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Prezime",
    dataIndex: "lastName",
    key: "lastName",
    align: "center",
  },
  {
    title: "Broj telefona",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    align: "center",
  },
  {
    title: "Deo restorana",
    dataIndex: "restaurantPart",
    key: "restaurantPart",
    align: "center",
  },
  {
    title: "Datum",
    dataIndex: "date",
    key: "date",
    align: "center",
  },
  {
    title: "Vreme",
    dataIndex: "time",
    key: "time",
    align: "center",
  },
  {
    title: "Broj ljudi",
    dataIndex: "nOfPeople",
    key: "nOfPeople",
    align: "center",
  },
  {
    title: "Poruka",
    dataIndex: "message",
    key: "message",
    align: "center",
  },
];

const initialValues = {
  message: undefined,
};

const Reservations = () => {
  const [reservations, setReservations] = useState([]);
  const [refetcher, setRefetcher] = useState();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const openNotification = (title, message, placement = "bottomRight") => {
    api.info({
      message: title || "",
      description: message || "",
      placement,
    });
  };

  const getReservations = async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/get-reservations`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });
      const itemData = data.map((item, i) => {
        return {
          key: i + 1,
          name: item.name,
          lastName: item.lastName,
          phoneNumber: item.phoneNumber,
          email: item.email,
          restaurantPart: item.restaurantPart,
          date: item.date,
          time: item.time,
          nOfPeople: item.nOfPeople,
          message: item?.message,
        };
      });
      setReservations(itemData);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getReservations();
  }, [refetcher]);

  return (
    <div>
      {contextHolder}
      <h1>Rezervacije</h1>
      <Row justify={"end"} style={{ paddingBottom: "10px" }}></Row>
      {loading ? (
        <Skeleton active />
      ) : (
        <Table dataSource={reservations} columns={columns} size={"small"} />
      )}
    </div>
  );
};
export default Reservations;
