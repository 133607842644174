import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  Row,
  Button,
  Modal,
  Input,
  notification,
  Popconfirm,
  Skeleton,
  Select,
  Radio,
  Tooltip,
  Tag,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import { SERVER_URL } from "../../config";
import { AuthContext } from "../../context/AuthContext";
import TextArea from "antd/es/input/TextArea";
import * as moment from 'moment';

const columns = [
  {
    title: "Promo kod",
    dataIndex: "code",
    key: "code",
    align: "center",
  },
  {
    title: "Opis",
    dataIndex: "description",
    key: "description",
    align: "center",
    className: "description-column", // Add a custom class for styling
    render: (description) => (
      <Tooltip title={description}>
        {" "}
        {/* Use Tooltip for full description */}
        <div className="description-cell">{description}</div>
      </Tooltip>
    ),
  },
  {
    title: "Važi od",
    dataIndex: "activationDate",
    key: "activationDate",
    align: "center",
    render: (timestamp) => {
      const options = {
        timeZone: "Europe/Paris",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // Use AM/PM format
      };
      return new Date(timestamp).toLocaleString("en-US", options);
    },
  },
  {
    title: "Važi do",
    dataIndex: "expirationDate",
    key: "expirationDate",
    align: "center",
    render: (timestamp) => {
      const options = {
        timeZone: "Europe/Paris",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // Use AM/PM format
      };
      return new Date(timestamp).toLocaleString("en-US", options);
    },
  },
  {
    title: "Minimalna cena por.",
    dataIndex: "minPrice",
    key: "minPrice",
    align: "center",
  },
  {
    title: "Tip popusta",
    dataIndex: "discountType",
    key: "discountType",
    align: "center",
  },
  {
    title: "Vrednost popusta",
    dataIndex: "discountAmount",
    key: "discountAmount",
    align: "center",
  },
  {
    title: "Iskoristivo jednom",
    dataIndex: "usableOnce",
    key: "usableOnce",
    align: "center",
  },
  {
    title: "Iskorišćeno",
    dataIndex: "used",
    key: "used",
    align: "center",
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    align: "center",
  },
];

const PromoCodes = () => {
  const [promoCodes, setPromoCodes] = useState([]);
  const [refetcher, setRefetcher] = useState();
  const [loading, setLoading] = useState(true);
  const [modalMode, setModalMode] = useState("");
  const [promoModal, setPromoModal] = useState(false);
  const [newPromoData, setNewPromoData] = useState({});
  const [currentProduct, setCurrentProduct] = useState();
  const { user } = useContext(AuthContext);
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (title, message, placement = "bottomRight") => {
    api.info({
      message: title || "",
      description: message || "",
      placement,
    });
  };

  const validateDate = (date) => {
    return moment(date, "DD.MM.YYYY.", true).isValid();
  };

  const onEditPromoCode = (item) => {
    setCurrentProduct(item);
    setModalMode("edit"); // Set modal mode to 'edit'
    setNewPromoData({
      code: item.code,
      description: item.description,
      activationDate: item.activationDate,
      expirationDate: item.expirationDate,
      minPrice: item.minPrice,
      discountType: item.discountPercentage ? "percentage" : "fixed",
      discountAmount: item.discountPercentage || item.discountFixed || "",
      usableOnce: item.usableOnce ? "Yes" : "No",
      used: item.used ? "Yes" : "No",
    });
    setPromoModal(true); // Open the modal
  };

  const clearNewPromoData = () => {
    setNewPromoData({
      code: undefined,
      description: undefined,
      activationDate: undefined,
      expirationDate: undefined,
      minPrice: undefined,
      discountType: undefined,
      discountAmount: undefined,
      usableOnce: undefined,
      used: undefined,
    });
  };

  const handleDelete = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/delete-promo-code/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });
      openNotification("Remove info", "Promo code removed successfully!");
      setRefetcher((r) => !r);
    } catch (error) {
      console.log(error.message);
      openNotification(
        `Remove error (Status: ${error.response.status})`,
        error.response?.data?.message
      );
    }
  };

  const getPromoCodes = async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/promo-codes`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });
      const itemData = data.map((item) => {
        return {
          code: item.code,
          description: item.description,
          activationDate: item.activationDate,
          expirationDate: item.expirationDate,
          minPrice: item.minPrice,
          discountType: item.discountPercentage ? "Procenat" : "Fiksno",
          discountAmount: item.discountPercentage || item.discountFixed || "",
          usableOnce: item.usableOnce ? (
            <Tag color="green">
              <CheckOutlined />
            </Tag>
          ) : (
            <Tag color="red">
              <CloseOutlined />
            </Tag>
          ),
          used: item.used ? (
            <Tag color="green">
              <CheckOutlined />
            </Tag>
          ) : (
            <Tag color="red">
              <CloseOutlined />
            </Tag>
          ),
          actions: (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <EditOutlined
                style={{ cursor: "pointer", paddingRight: "8px" }}
                onClick={() => onEditPromoCode(item)}
              />
              <Popconfirm
                title="Delete a product"
                description="Are you sure to delete this product?"
                onConfirm={() => handleDelete(item._id)}
                okText="Yes"
                cancelText="No"
                placement="left"
              >
                <DeleteOutlined style={{ cursor: "pointer" }} />
              </Popconfirm>
            </div>
          ),
        };
      });
      setPromoCodes(itemData);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getPromoCodes();
  }, [refetcher]);

  const onPromoCodeCreate = async () => {
    try {
      // Check if the discount type is chosen as "fixed" or "percentage"
      if (
        (newPromoData.discountType === "fixed" ||
          newPromoData.discountType === "percentage") &&
        !newPromoData.discountAmount
      ) {
        openNotification(
          "Validation Error",
          "Please enter a valid discount amount."
        );
        return;
      }
      // Validate the activation and expiration dates
      if (!validateDate(newPromoData.activationDate) || !validateDate(newPromoData.expirationDate)) {
        openNotification(
          "Validation Error",
          "Please enter valid dates in DD.MM.YYYY. format."
        );
        return;
      }
      // Create a payload object
      const payload = {
        code: newPromoData.code,
        description: newPromoData.description,
        activationDate: newPromoData.activationDate,
        expirationDate: newPromoData.expirationDate,
        minPrice: newPromoData.minPrice,
        discountType: newPromoData.discountPercentage ? "Percentage" : "Fixed",
        discountAmount:
          newPromoData.discountPercentage || newPromoData.discountFixed || "",
        usableOnce: newPromoData.usableOnce === "Yes",
        used: newPromoData.used === "Yes",
      };

      // Set the discount field based on the selected discount type
      if (newPromoData.discountType === "fixed") {
        payload.discountFixed = parseFloat(newPromoData.discountAmount);
      } else if (newPromoData.discountType === "percentage") {
        payload.discountPercentage = parseFloat(newPromoData.discountAmount);
      } else {
        payload.discountPercentage = undefined;
      }
      const response = await Axios.post(
        `${SERVER_URL}/create-promo-code`,
        payload,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      openNotification("Create Successful", "Promo code created successfully!");
      setPromoModal(false); // Close the modal
      setRefetcher((r) => !r); // Refetch promo codes to update the table
      clearNewPromoData(); // Clear the form fields
    } catch (error) {
      console.log(error.message);
      openNotification(
        `Create Error (Status: ${error.response.status})`,
        error.response?.data?.message
      );
    }
  };

  const onPromoCodeUpdate = async () => {
    try {
      // Create a payload object
      const payload = {
        code: newPromoData.code,
        description: newPromoData.description,
        activationDate: newPromoData.activationDate,
        expirationDate: newPromoData.expirationDate,
        minPrice: newPromoData.minPrice,
        usableOnce: newPromoData.usableOnce === "Yes",
        used: newPromoData.used === "Yes",
      };

      // Set the discount field based on the selected discount type
      if (newPromoData.discountType === "fixed") {
        payload.discountFixed = parseFloat(newPromoData.discountAmount);
      } else if (newPromoData.discountType === "percentage") {
        payload.discountPercentage = parseFloat(newPromoData.discountAmount);
      }
      await Axios.put(
        `${SERVER_URL}/update-promo-code/${currentProduct._id}`, // Use the correct API endpoint
        payload,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      openNotification("Update Successful", "Product updated successfully!");
      setPromoModal(false); // Close the modal
      setRefetcher((r) => !r); // Refetch products to update the table
    } catch (error) {
      console.log(error.message);
      openNotification(
        `Update Error (Status: ${error.response.status})`,
        error.response?.data?.message
      );
    }
  };

  return (
    <>
      {contextHolder}
      <h1>Promo kodovi</h1>
      <Row justify={"end"} style={{ paddingBottom: "10px" }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size={"large"}
          onClick={() => {
            setPromoModal(true);
            setModalMode("create");
          }}
        >
          Kreiraj novi
        </Button>
      </Row>
      {loading ? (
        <Skeleton active />
      ) : (
        <Table dataSource={promoCodes} columns={columns} size={"small"} />
      )}
      <Modal
        title={
          modalMode === "create" ? "Kreiraj novi promo kod" : "Izmeni promo kod"
        }
        centered
        open={promoModal}
        onOk={
          modalMode === "create"
            ? () => {
                onPromoCodeCreate();
              }
            : () => {
                onPromoCodeUpdate();
                clearNewPromoData();
              }
        }
        onCancel={() => {
          setPromoModal(false);
          if (modalMode === "edit") {
            clearNewPromoData();
          }
        }}
      >
        <Row>
          <div style={{ marginTop: "10px" }}>Promo kod:</div>
          <Input
            name={"code"}
            placeholder="Title"
            value={newPromoData?.code}
            onChange={(e) =>
              setNewPromoData((d) => {
                return { ...d, code: e.target.value };
              })
            }
          />

          <div style={{ marginTop: "10px" }}>Opis:</div>
          <TextArea
            style={{ height: "110px" }}
            name={"description"}
            placeholder="Description"
            value={newPromoData?.description}
            onChange={(e) =>
              setNewPromoData((d) => {
                return { ...d, description: e.target.value };
              })
            }
          />

          <div style={{ marginTop: "10px" }}>Minimalna cena porudžbine:</div>
          <Input
            name={"Minimum price"}
            placeholder=""
            value={newPromoData?.minPrice}
            onChange={(e) =>
              setNewPromoData((d) => {
                return { ...d, minPrice: e.target.value };
              })
            }
          />

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: "10px" }}>Tip popusta:</div>
            <Radio.Group
              onChange={(e) =>
                setNewPromoData((d) => {
                  return { ...d, discountType: e.target.value };
                })
              }
              value={newPromoData?.discountType || undefined} // Default to 'fixed'
            >
              <Radio value="fixed">Popust fiksno</Radio>
              <Radio value="percentage">Popust procenat</Radio>
            </Radio.Group>
          </div>
          {newPromoData.discountType !== undefined && (
            <Row style={{ marginTop: "10px" }}>
              <div style={{ marginBottom: "10px" }}>Cena popusta:</div>
              <Input
                name={"discountAmount"}
                placeholder=""
                value={newPromoData?.discountAmount}
                onChange={(e) =>
                  setNewPromoData((d) => {
                    return { ...d, discountAmount: e.target.value };
                  })
                }
                type="number" // Make sure the input accepts only numeric values
              />
            </Row>
          )}

          <Row style={{ marginTop: "10px" }}>
            <div style={{ marginBottom: "10px" }}>Datum aktiviranja:</div>
            <Input
              name={"activationDate"}
              placeholder="DD.MM.YYYY."
              value={newPromoData?.activationDate}
              onChange={(e) =>
                setNewPromoData((d) => {
                  return { ...d, activationDate: e.target.value };
                })
              }
            />
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <div style={{ marginTop: "10px" }}>Datum isteka:</div>
            <Input
              name={"expirationDate"}
              placeholder="DD.MM.YYYY."
              value={newPromoData?.expirationDate}
              onChange={(e) =>
                setNewPromoData((d) => {
                  return { ...d, expirationDate: e.target.value };
                })
              }
            />
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <div style={{ marginTop: "10px", width: "100px" }}>
              Iskoristivo jednom:
            </div>
            <Select
              style={{ width: "50%" }}
              value={newPromoData?.usableOnce === "Da"} // Set value to true if 'Yes' is selected
              onChange={(value) =>
                setNewPromoData((d) => ({
                  ...d,
                  usableOnce: value ? "Da" : "Ne",
                }))
              }
            >
              <Select.Option value={true}>Da</Select.Option>
              <Select.Option value={false}>Ne</Select.Option>
            </Select>
            <div style={{ marginTop: "10px", width: "100px" }}>
              Iskorišćeno:
            </div>
            <Select
              style={{ width: "50%" }}
              value={newPromoData?.used === "Yes"} // Set value to true if 'Yes' is selected
              onChange={(value) =>
                setNewPromoData((d) => ({ ...d, used: value ? "Da" : "Ne" }))
              }
            >
              <Select.Option value={true}>Da</Select.Option>
              <Select.Option value={false}>Ne</Select.Option>
            </Select>
          </Row>
        </Row>
      </Modal>
    </>
  );
};

export default PromoCodes;
