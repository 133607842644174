export const inactivityReload = (timeout = 300000) => {
  let timeoutRef;

  const resetTimer = () => {
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }

    // Set the timer for the specified inactivity time (default: 5 minutes)
    timeoutRef = setTimeout(() => {
      window.location.reload();
    }, timeout);
  };

  const handleMouseMove = () => {
    resetTimer();
  };

  // Add mousemove event listener to reset the timer on activity
  window.addEventListener("mousemove", handleMouseMove);

  // Start the timer when this function is called
  resetTimer();

  // Cleanup function: call this to remove the event listener and clear the timeout
  return () => {
    window.removeEventListener("mousemove", handleMouseMove);
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
  };
};
