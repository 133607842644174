import React, { useState, useContext } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Layout, Menu, theme } from "antd";
import {
  UserOutlined,
  DesktopOutlined,
  PieChartOutlined,
  UnorderedListOutlined,
  StarOutlined,
  TableOutlined,
  SettingOutlined,
  LogoutOutlined,
  UserDeleteOutlined,
  TagsOutlined,
  FileJpgOutlined,
  InsertRowLeftOutlined,
} from "@ant-design/icons";
import DashboardHome from "./DashboardHome";
import Categories from "./Categories";
import { AuthContext } from "../../context/AuthContext";
import Logout from "../auth/Logout";
import Products from "./Products";
import Users from "./Users";
import DashboardUsers from "./DashboardUser";
import ProductsDetails from "./ProductsDetails";
import CategoriesDetails from "./CategoriesDetails";
import SideDishes from "./SideDishes";
import Orders from "./Orders";
import PromoCodes from "./PromoCodes";
import Gallery from "./Gallery";
import Reservations from "./Reservations";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const location = useLocation();

  const { user, logout } = useContext(AuthContext);

  const getDefaultSelectedKey = (path) => {
    const pathArr = path.split("/");
    if (pathArr.length > 1) {
      return [`/${pathArr[1]}`];
    } else {
      return ["/"];
    }
  };

  // Define the menu items based on the user's role
  const items = [
    getItem("Home", "/", <DesktopOutlined />),
    user?.role === "admin" && getItem("Kategorije", "/categories", <UnorderedListOutlined />),
    user?.role === "admin" && getItem("Proizvodi", "/products", <PieChartOutlined />),
    user?.role === "admin" && getItem("Dodaci", "/side-dishes", <StarOutlined />),
    getItem("Porudžbine", "/orders", <TableOutlined />),
    user?.role === "admin" && getItem("Rezervacije", "/reservations", <InsertRowLeftOutlined />),
    user?.role === "admin" && getItem("Promo kodovi", "/promo-codes", <TagsOutlined />),
    user?.role === "admin" && getItem("Korisnici", "/get-all-users", <UserOutlined />),
    user?.role === "admin" && getItem("Dashboard Korisnici", "/get-all-dashboard-users", <UserDeleteOutlined />),
    user?.role === "admin" && getItem("Galerija", "/gallery", <FileJpgOutlined />),
    user?.role === "admin" && getItem("Podešavanja", "/settings", <SettingOutlined />),
    getItem("Izloguj se", "/logout", <LogoutOutlined />),
  ].filter(Boolean); // Remove any false values

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center', marginLeft:'20px' }}>
          <img src="/favicon.ico" alt="BH" style={{ height:'30px', width:'50px', marginTop:'15px', marginRight:'10px' }} />
          <p style={{ color: "white", textAlign: "center", fontSize: "20px" }}>
            {collapsed ? "BH" : "Basch House"}
          </p>
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={getDefaultSelectedKey(location.pathname)}
          selectedKeys={getDefaultSelectedKey(location.pathname)}
          mode="inline"
          items={items}
          onClick={({ key }) => navigate(key)}
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: "#001529" }}></Header>
        <Content style={{ margin: "16px 16px" }}>
          <div style={{ padding: 24, minHeight: 760, background: colorBgContainer }}>
            <Routes>
              {<Route path="/" element={<DashboardHome />} />}
              {user?.role === "admin" && <Route path="/categories" element={<Categories />} />}
              {user?.role === "admin" && <Route path="/categories/details/:id" element={<CategoriesDetails />} />}
              {user?.role === "admin" && <Route path="/products" element={<Products />} />}
              {user?.role === "admin" && <Route path="/products/details/:id" element={<ProductsDetails />} />}
              {user?.role === "admin" && <Route path="/side-dishes" element={<SideDishes />} />}
              <Route path="/orders" element={<Orders />} />
              {user?.role === "admin" && <Route path="/reservations" element={<Reservations />} />}
              {user?.role === "admin" && <Route path="/promo-codes" element={<PromoCodes />} />}
              {user?.role === "admin" && <Route path="/get-all-users" element={<Users />} />}
              {user?.role === "admin" && <Route path="/get-all-dashboard-users" element={<DashboardUsers />} />}
              {user?.role === "admin" && <Route path="/gallery" element={<Gallery />} />}
              {user?.role === "admin" && <Route path="/settings" element={<p>Settings</p>} />}
              <Route path="/logout" element={<Logout logout={logout} />} />
              <Route path="*" element={<p>Not found 404</p>} />
            </Routes>
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Basch House Subotica ©2023 Created by ConcordSoft Solutions
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
