// Gallery.js
import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Button,
  Upload,
  message,
  List,
  Image,
  Modal,
  notification,
  Tooltip,
  Popconfirm,
  Input,
  Pagination,
  Skeleton,
} from "antd";
import Axios from "axios";
import { SERVER_URL } from "../../config"; // Make sure this points to your server URL
import { AuthContext } from "../../context/AuthContext";
import "../../styles/Gallery.css";

import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";

const Gallery = () => {
  const { user } = useContext(AuthContext);
  const [toggle, setToggle] = useState(false);
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [imageOptionsVisible, setImageOptionsVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const imageRef = useRef();
  const hiddenFileInput = useRef();

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (title, message, placement = "bottomRight") => {
    api.info({
      message: title || "",
      description: message || "",
      placement,
    });
  };

  // Function to fetch images from the server
  const getImages = async () => {
    const offset = currentPage * 12 - 12;
    const limit = 12;
    setLoading(true);

    try {
      const response = await Axios.get(
        `${SERVER_URL}/get-images?skip=${offset}&limit=${limit}`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      setImages(response.data);
      openNotification(
        "Image upload",
        "Image uploaded successfully, please reload the page.."
      );
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  // Function to handle image deletion
  const handleDelete = async (image) => {
    try {
      await Axios.delete(`${SERVER_URL}/delete-image/${image._id}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setImages((prevImages) =>
        prevImages.filter((img) => img._id !== image._id)
      );
      message.success("Image deleted successfully.");
    } catch (error) {
      console.error("Error deleting image:", error);
      message.error("Image deletion failed.");
    }
  };

  // Function to handle image preview
  const handlePreview = (image) => {
    setPreviewImage(image.url);
    setPreviewVisible((prevState) => ({
      ...prevState,
      [image._id]: !prevState[image._id],
    }));
  };

  const handleAddImage = () => {
    hiddenFileInput.current.click();
  };

  // Handle file selection
  const handleImageSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleImageUpload(file);
    }
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  // Fetch images on component mount
  useEffect(() => {
    getImages();
  }, [currentPage]);

  const handleImageUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("image", imageRef.current.files[0]);

      const response = await Axios.post(
        `${SERVER_URL}/upload-image`,
        formData,
        {
          withCredentials: false,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    } catch (error) {
      console.error("Upload Error: ", error);
      // Optionally: Provide user feedback on failure.
    }
  };

  console.log("images", images);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1>Galerija</h1>
        <div style={{ paddingLeft: "20px", display: "flex", gap: "20px" }}>
          <Button
            onClick={() => setToggle((t) => !t)}
            type="button"
            style={{ backgroundColor: "#1677ff", color: "#fff" }}
          >
            Dodaj sliku
          </Button>
          {toggle && (
            <div>
              <input
                id="fileInput"
                type="file"
                ref={imageRef}
                onChange={handleImageUpload}
                accept="image/*"
              />
            </div>
          )}
        </div>
        <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
          <Pagination
            current={currentPage}
            pageSize={12}
            total={images?.imageCount}
            onChange={(page) => handlePageChange(page)}
            showSizeChanger={false} // Optional: Hide page size changer
            style={{
              marginTop: "10px",
              textAlign: "center",
              position: "static",
            }}
          />
        </div>
      </div>
      {loading ? (
        <Skeleton active />
      ) : (
        <List
          style={{
            display: "grid",
            gap: "20px",
          }}
          grid={{ gutter: 32, column: 6 }}
          dataSource={images?.images}
          renderItem={(image) => (
            <div
              id={`gallery-img-${image._id}`}
              style={{
                minHeight: "150px",
                width: "100%",
                position: "relative",
                // background: "#ccc",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="gallery-image-container"
              onMouseEnter={() => setImageOptionsVisible(image._id)}
              onMouseLeave={() => setImageOptionsVisible(null)}
            >
              <Image
                src={image.url}
                alt={image.originalname}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
                preview={{
                  visible: previewVisible[image._id],
                  onVisibleChange: (visible, prevVisible) =>
                    setPreviewVisible((prevState) => ({
                      ...prevState,
                      [image._id]: visible,
                    })),
                }}
              />
              {imageOptionsVisible === image._id ? (
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    width: "100%",
                    height: "100%",
                    background: "rgba(0, 0, 0, 0.6)",
                    borderRadius: "5px",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  <EyeOutlined
                    style={{ padding: "5px" }}
                    title="Preview image"
                    onClick={() => handlePreview(image)}
                  />
                  <Popconfirm
                    placement="left"
                    title="This will delete this image"
                    onConfirm={() => handleDelete(image)}
                    okText="Delete"
                    cancelText="Cancel"
                  >
                    <DeleteOutlined
                      style={{ padding: "5px" }}
                      title="Delete image"
                    />
                  </Popconfirm>
                </div>
              ) : (
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    width: "100%",
                    height: "100%",
                    margin: "10px",

                    borderRadius: "5px",
                    color: "white",
                    fontSize: "20px",
                  }}
                ></div>
              )}
            </div>
            // </Tooltip>
          )}
        />
      )}
    </div>
  );
};

export default Gallery;
